import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { isEmail } from "../../helpers/Validators";

export default function EmailFormDialog(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const HandleExportingData = () => {
    if (isEmail(email)) {
      props.handleExport(props.selected, email);
      props.handleClose();
      setEmail("");
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <Dialog open={props.isOpen} onClose={props.handleClose}>
        <DialogContent>
          <DialogContentText>
            Please enter your email address here. We will send excel sheet.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            error={error}
            helperText={error ? "Please enter a valid email address" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={() => HandleExportingData()}>Send Now</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
