import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  Fragment,
} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateInput from "../../components/InputFields/DateInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import SelectInput from "../../components/InputFields/SelectInput";
import {
  yupPhone,
  yupRequiredTranslatableString,
} from "../../helpers/Validators";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD";

const empty = {
  title: "",
  value: "",
  price: "",
  is_active: 1,
  is_private: 0,
  unit: "",
  max_duration_to_finish: "",
  from: moment().format(DATE_FORMAT),
  to: moment().format(DATE_FORMAT),
  is_seasonal: 0,
};

const MODULE_URL = "admin/packages";

function PackagesFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [isNumberOfSessions, setIsNumberOfSessions] = useState(false);

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          setItem(res.data.data);
          setIsNumberOfSessions(res.data.data.unit === "times.day" ? 0 : 1);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              // title: yupRequiredTranslatableString(),
              // note: yupRequiredTranslatableString(null, false),
              unit: Yup.string()
                .oneOf(["times.day", "sessions.count"])
                .required("Required field"),
              value: Yup.number().min(1).required("Required field"),
              price: Yup.number().min(1).required("Required field"),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };
              if (input.is_seasonal == 0) {
                delete input["from"];
                delete input["to"];
              }
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, values } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="title"
                      label="Please enter package title"
                      grid={6}
                    />
                    <SelectInput
                      name="unit"
                      key="unit"
                      label="Select package pricing unit"
                      grid={3}
                      onChangeHook={(e) => {
                        setIsNumberOfSessions(
                          e.target.value === "times.day" ? 0 : 1
                        );
                      }}
                      options={[
                        { label: "By days", value: "times.day" },
                        {
                          label: "By number of sessions",
                          value: "sessions.count",
                        },
                      ]}
                      setFieldValue={setFieldValue}
                    />
                    <TextInput
                      key="value"
                      name="value"
                      label="Please enter value"
                      grid={isNumberOfSessions ? 3 : 6}
                    />
                    {isNumberOfSessions ? (
                      <TextInput
                        key="max_duration_to_finish"
                        name="max_duration_to_finish"
                        label="Expired after/days"
                        grid={3}
                      />
                    ) : (
                      ""
                    )}
                    <TextInput
                      key="price"
                      name="price"
                      label="Package price"
                      grid={3}
                    />
                    <SwitchInput
                      name="is_private"
                      label="Is Private"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <SwitchInput
                      name="is_seasonal"
                      label="Is Seasonal"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    {values.is_seasonal == 1 && (
                      <>
                        <DateInput
                          name="from"
                          label="Start at:"
                          grid={6}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          errors={errors}
                        />
                        <DateInput
                          name="to"
                          label="Ends at:"
                          grid={6}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          minDate={values.from}
                          errors={errors}
                        />
                      </>
                    )}
                    <TranslatableTextAreaInput
                      translations={item.allTranslations}
                      name="description"
                      label="Description"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default PackagesFormDialog;
