import React from "react";
import { useField } from "formik";
import { FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  checkBox: {},
});

function SwitchInput(props) {
  const [field] = useField(props);
  const { value, onChange, name } = field;
  const { label, grid, setFieldValue } = props;
  const classes = useStyles();

  const handleChange = (e, check) => {
    setFieldValue(name, check ? 1 : 0);
  };

  return (
    <Grid item xs={grid}>
      <FormControlLabel
        className={classes.checkBox}
        control={
          <Switch
            name={name}
            checked={value == 1}
            onChange={handleChange}
            color="secondary"
          />
        }
        label={label}
      />
    </Grid>
  );
}

export default SwitchInput;
