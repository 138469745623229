import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const useStyles = makeStyles({});

function MultiSelectInput(props) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [field, meta] = useField(props);
  const { name, onBlur } = field;
  const {
    label,
    grid,
    options,
    setFieldValue,
    value,
    closeMenuOnSelect,
    components,
    valueKey,
    labelKey,
  } = props;
  const { error, touched } = meta;
  useEffect(() => {
    if (value && Array.isArray(value) && options) {
      const items = [];
      options.forEach((option) => {
        if (value.includes(option[valueKey])) {
          items.push({
            value: option[valueKey],
            label: option[labelKey],
          });
        }
      });
      setSelectedItems(items);
    }
  }, [value, options]);

  useEffect(() => {
    setFieldValue(
      name,
      selectedItems.map((item) => item.value)
    );
  }, [selectedItems]);

  const handleChange = (items) => {
    setSelectedItems(items);
  };
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid rgb(55, 17, 109, 0.5)",
      // This line disable the blue border
      boxShadow: "none",

      "&:hover": {
        border: "1px solid rgb(55, 17, 109, 0.5)",
      },
    }),
  };

  return (
    <Grid item xs={grid}>
      <InputLabel style={{ marginBottom: "1vw" }}>{label}</InputLabel>
      <Select
        id={name}
        name={name}
        options={options.map((option) => ({
          value: option[valueKey],
          label: option[labelKey],
        }))}
        isMulti
        onChange={handleChange}
        value={selectedItems}
        onBlur={onBlur}
        components={components}
        closeMenuOnSelect={closeMenuOnSelect}
        styles={style}
        // helperText={error && touched && error}
        errorText={error && touched}
      />
      <div>
        {touched && error && (
          <div style={{ fontSize: "14px", color: "rgb(244, 67, 54)" }}>
            {error}
          </div>
        )}
      </div>
    </Grid>
  );
}

MultiSelectInput.defaultProps = {
  valueKey: "value",
  labelKey: "label",
};

export default MultiSelectInput;
