import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AppContext from "../../../context/AppContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import SelectInput from "../../../components/InputFields/SelectInput";
import { Fragment } from "react";
import MultiSelectInput from "../../../components/InputFields/MultiSelectInput";
import { api } from "../../../constants/Constants";
import FilterAndSelectUsers from "../../../components/FilterAndSelectUsers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableView: {
    margin: theme.spacing(2),
  },
}));

export default function FiltrationStep(props) {
  const classes = useStyles();
  const { errors, setFieldError, setFieldValue, values } = props;

  const [userTypes, setUserTypes] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSelectUsersOpen, setIsSelectUsersOpen] = useState(false);

  const context = useContext(AppContext);
  const { changeRefreshing } = context;

  useEffect(() => {
    getUserTypes();
  }, []);

  const getUserTypes = () => {
    changeRefreshing(true);
    // API Call
    setUserTypes([
      { label: "Owner", value: 1 },
      { label: "Mate", value: 2 },
      { label: "Guest", value: 3 },
    ]);
    changeRefreshing(false);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <SelectInput
          name="filtrationType"
          label="Select filtration type"
          grid={12}
          options={[
            { label: "Default", value: 0 },
            { label: "Specific users", value: 1 },
          ]}
          setFieldValue={setFieldValue}
        />
        {values.filtrationType == 0 && (
          <Fragment>
            <SelectInput
              name="devices"
              label="Select devices types"
              grid={6}
              options={[
                { label: "All", value: 0 },
                { label: "IOS", value: 1 },
                { label: "Android", value: 2 },
              ]}
              setFieldValue={setFieldValue}
            />
            <SelectInput
              name="genders"
              label="Select genders"
              grid={6}
              options={[
                { label: "All", value: 0 },
                { label: "Male", value: 1 },
                { label: "Female", value: 2 },
              ]}
              setFieldValue={setFieldValue}
            />
            {/* <MultiSelectInput
              name="selectedUserTypes"
              label="Select user types"
              grid={6}
              options={userTypes}
              closeMenuOnSelect={false}
              setFieldValue={setFieldValue}
            /> */}
          </Fragment>
        )}
        {values.filtrationType == 1 && (
          <Fragment>
            <Grid item xs={12} className={classes.tableView}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>First name</TableCell>
                    <TableCell>Last name</TableCell>
                    <TableCell>Phone number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedUsers.map((user) => (
                    <TableRow>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.first_name}</TableCell>
                      <TableCell>{user.last_name}</TableCell>
                      <TableCell>{user.phone}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <FilterAndSelectUsers
                open={isSelectUsersOpen}
                updateUsers={(users) => {
                  setSelectedUsers(users);
                  setFieldValue("selectedUsers", users);
                }}
                users={selectedUsers}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </div>
  );
}
