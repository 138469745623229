import React, { Component, Fragment } from "react";
import PhoneDirectoryCategoriesFormDialog from "./PhoneDirectoryCategoriesFormDialog";
import PhoneDirectoryCategoriesListing from "./PhoneDirectoryCategoriesListing";
import FAB from "../../components/FAB";
import { api } from "../../constants/Constants";
import WithAppContext from "../../context/WithAppContext";
import Paginator from "../../components/Paginator";

const MODULE_URL = "/admin/phone_directory_categories";

class PhoneDirectoryCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      page: 1,
      nextPage: null,
      prevPage: null,
      lastPage: null,
      filters: [],
      currentItem: null,
      openItemsFormDialog: false,
    };
  }

  componentDidMount() {
    this.getAll();
  }

  getAll = () => {
    this.props.changeLoading(true);
    const { page, filters } = this.state;
    let filters_string = JSON.stringify(filters);
    api
      .get(`${MODULE_URL}?page=${page}&filters=${filters_string}`)
      .then((res) => {
        const response = res.data.data;
        this.setState(
          {
            items: response.data,
            nextPage: response.next_page_url,
            prevPage: response.prev_page_url,
            lastPage: response.last_page,
          },
          () => {
            this.props.changeLoading(false);
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleNext = () => {
    let page = this.state.page;
    page += 1;
    this.setState({ page: page }, () => {
      this.getAll();
    });
  };

  handlePrev = () => {
    let page = this.state.page;
    page -= 1;
    this.setState({ page: page }, () => {
      this.getAll();
    });
  };

  handleLastPage = () => {
    let page = this.state.lastPage;
    this.setState({ page: page }, () => {
      this.getAll();
    });
  };

  handleFirstPage = () => {
    this.setState({ page: 1 });
  };

  handleClear = () => {
    this.setState(
      {
        filters: "",
        page: 1,
        nextPage: null,
        prevPage: null,
        lastPage: null,
      },
      () => this.getAll()
    );
  };

  fabCallback = () => {
    if (!this.props.checkAuthorization("create", "PhoneDirectoryCategories")) {
      this.props.showSnack(
        "You don't have permission to create phone directory categories",
        "error"
      );
    } else {
      this.setState({
        openItemsFormDialog: true,
        currentItem: null,
      });
    }
  };

  handleCloseItemsFormDialog = () => {
    this.setState({
      openItemsFormDialog: false,
      currentItem: null,
    });
  };

  handleOpenEditFrom = (id) => {
    if (!this.props.checkAuthorization("update", "PhoneDirectoryCategories")) {
      this.props.showSnack(
        "You don't have permission to edit phone directory categories",
        "error"
      );
    } else {
      this.setState({
        currentItem: id,
        openItemsFormDialog: true,
      });
    }
  };

  handleDelete = (toBeDeleted) => {
    this.props.changeRefreshing(true);
    const data = {};
    let url = `${MODULE_URL}/`;
    const method = "POST";
    if (toBeDeleted instanceof Array) {
      url += "destroyMany";
      data.ids = toBeDeleted;
    } else {
      url += toBeDeleted.toString();
    }
    data._method = "DELETE";
    api({ method, url, data })
      .then((res) => {
        this.props.changeRefreshing(false);
        this.getAll();
        console.log(res);
      })
      .catch((err) => {
        this.props.changeRefreshing(false);
        console.log(err);
      });
  };

  handleFilter = (filters) => {
    this.setState({ filters }, () => {
      this.getAll();
    });
  };

  handleExport = (selected) => {
    this.props.changeLoading(true);
    const { filters } = this.state;
    let filters_string = JSON.stringify(filters);
    let data = JSON.stringify(selected);
    let url = "";
    if (selected.length > 0) {
      if (filters && filters.length > 0) {
        url = `${MODULE_URL}/export?selected=${data}&filters=${filters_string}`;
      } else {
        console.log(selected);
        url = `${MODULE_URL}/export?selected=${data}`;
      }
    } else {
      if (filters && filters.length > 0) {
        url = `${MODULE_URL}/export?&filters=${filters_string}`;
      } else {
        url = `${MODULE_URL}/export`;
      }
    }

    api
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "phone_directory_categories.xlsx"); //or any other extension
        document.body.appendChild(link);
        this.props.changeLoading(false);
        link.click();
      })
      .catch((err) => {
        this.props.changeLoading(false);
        console.log(err);
      });
  };

  render() {
    const { items, page, nextPage, prevPage, lastPage } = this.state;
    return (
      <Fragment>
        <PhoneDirectoryCategoriesListing
          handleFilter={this.handleFilter}
          data={items}
          handleDelete={this.handleDelete}
          handleExport={this.handleExport}
          handleOpenEditFrom={this.handleOpenEditFrom}
        />
        {items && items.length > 0 && (
          <Paginator
            page={page}
            nextPage={nextPage}
            prevPage={prevPage}
            lastPage={lastPage}
            handlePrev={this.handlePrev}
            handleNext={this.handleNext}
            handleLastPage={this.handleLastPage}
            handleFirstPage={this.handleFirstPage}
          />
        )}
        <PhoneDirectoryCategoriesFormDialog
          handleClose={this.handleCloseItemsFormDialog}
          handleSuccess={() => {
            this.setState({ openItemsFormDialog: false }, () => {
              this.getAll();
            });
          }}
          isOpen={this.state.openItemsFormDialog}
          id={this.state.currentItem}
        />
        <FAB callback={this.fabCallback} />
      </Fragment>
    );
  }
}

export default WithAppContext(PhoneDirectoryCategories);
