import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import Select from "react-select";
import TextInput from "../../components/InputFields/TextInput";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 400,
  }),
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty = {
  username: "",
  userphone: "",
  category: "",
  timerange: "",
  date: "",
  categoryitem: "",
};
const MODULE_URL = "admin/service_request_rates";

function ServiceRequestRatesFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();
  const [item, setItem] = useState(empty);

  const [state, setState] = useState();
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          setItem(res.data.data);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);
  useEffect(() => {
    let state = 0;
    if (item.state == 0) {
      state = { value: 0, label: "Pending" };
      setState(state);
    } else if (item.state == 1) {
      state = { value: 1, label: "Fullfilled" };
      setState(state);
    } else if (item.state == 2) {
      state = { value: 2, label: "Other" };
      setState(state);
    }
  }, [item.state]);
  console.log("item", item);
  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  const selectOptions = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Fullfilled" },
    { value: 2, label: "Other" },
  ];
  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        {/* <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
       c
            </Typography>
          </Toolbar>
        </AppBar> */}
        <DialogTitle id="responsive-dialog-title">
          {"Rating Information"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className={classes.root}>
              <Formik
                enableReinitialize={true}
                innerRef={formRef}
                initialValues={item}
                onSubmit={async (values, {setSubmitting, setErrors, setFieldError}) => {
                  await new Promise((r) => setTimeout(r, 500));
                  changeRefreshing(true);
                  setSubmitting(true);
                  let method = "POST";
                  let url = `${MODULE_URL}`;
                  const input = { ...values, state: state.value };
                  if (id) {
                    input._method = "PUT";
                    url += "/" + id;
                  }
                  const data = transformObjectToFromData(input);
                  api({ method, url, data })
                    .then((res) => {
                      changeRefreshing(false);
                      setSubmitting(false);
                      handleSuccess();
                    })
                    .catch((err) => {
                      changeRefreshing(false);
                      setSubmitting(false);
                      console.log("error", err);
                      if (err.response) {
                        setErrors(err.response.data);
                      }
                    });
                }}
              >
                {(props) => {
                  const { errors, setFieldError, setFieldValue } = props;
                  return (
                    <form>
                      <Grid container spacing={3}>
                        <TextInput
                          name="username"
                          label="User Name"
                          grid={6}
                          disabled={true}
                        />
                        <TextInput
                          name="userphone"
                          label="Phone"
                          grid={6}
                          disabled={true}
                        />
                        <TextInput
                          name="category.name"
                          label="Category"
                          grid={6}
                          disabled={true}
                        />
                        <TextInput
                          name="categoryitem.name"
                          label="Category Item"
                          grid={6}
                          disabled={true}
                        />
                        <TextInput
                          name="date"
                          label="Date"
                          grid={6}
                          disabled={true}
                        />
                        <TextInput
                          name="timerange"
                          label="Time Range"
                          grid={6}
                          disabled={true}
                        />
                        {/* <Grid container spacing={12} xs={12}>
                      <Select
                        placeholder="State"
                        label="Select State"
                        id="color"
                        options={selectOptions}
                        styles={customStyles}
                        closeMenuOnSelect={true}
                        value={state}
                        onChange={setState}
                      />
                    </Grid> */}
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ServiceRequestRatesFormDialog;
