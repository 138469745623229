import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DropzoneComponent from "./DropzoneComponent";

export default class AddDirectoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  addFiles = files => {
    this.setState({ files });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
            this.props.handleOpen(false);
          }}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">Add Media</DialogTitle>
          <DialogContent>
            <DropzoneComponent addFiles={this.addFiles} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.props.handleOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.props.handleSuccess(this.state.files);
              }}
              color="primary"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
