import React, { useContext, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import SchedulesFilters from "./SchedulesFilters";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import moment from "moment";
import WithAppContext from "../../context/WithAppContext";
import GetAppIcon from "@material-ui/icons/GetApp";
import SortIcon from "@material-ui/icons/Sort";
import AppContext from "../../context/AppContext";
import {GridRow} from "semantic-ui-react";
import {Grid} from "@material-ui/core";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
  root2: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root3: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
});

const useStyles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  tableCellStyles: {
    padding: "15px",
  },
  resendButton: {
    marginLeft: theme.spacing(2),
  },
});

function EnhancedTableToolbar(props) {
  const {
    classes,
    handleFilter,
    numSelected,
    handleExport,
    handleOpenSorting,
    selected,
  } = props;
  return (
    <div>
      <Toolbar className={classNames(classes.root)}>
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            Schedules
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 && (
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                onClick={() => props.handleDeleteButton(selected)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </div>
  );
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

function SchedulesListing(props) {
  const [selected, setSelected] = useState([]);
  const [sorting, setSorting] = useState({
    order: "desc",
    orderBy: "id",
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    items: [],
  });

  const context = useContext(AppContext);
  const { showSnack, checkAuthorization } = context;

  const {
    handleDelete,
    classes,
    data,
    locationId,
    handleOpenEditFrom,
    handleFilter,
    handleExport,
    handleOpenSorting,
    handleStatusChange,
  } = props;
  const handleRequestSort = (event, property) => {
    const tempOrderBy = property;
    let tempOrder = "desc";
    const { orderBy, order } = sorting;
    if (orderBy === property && order === "desc") {
      tempOrder = "asc";
    }
    setSorting({ order: tempOrder, orderBy: tempOrderBy });
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      setSelected(props.data.map((n) => n.period));
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const tempSelected = [...selected];
    const selectedIndex = tempSelected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(tempSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(tempSelected.slice(1));
    } else if (selectedIndex === tempSelected.length - 1) {
      newSelected = newSelected.concat(tempSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        tempSelected.slice(0, selectedIndex),
        tempSelected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleDeleteButton = (toBeDeleted) => {
    if (!checkAuthorization("delete", "Schedules")) {
      showSnack("You don't have permission to delete schedules", "error");
    } else {
      handleOpenConfirmation(toBeDeleted);
    }
  };

  const handleOpenConfirmation = (toBeDeleted) => {
    setDeleteDialog({ open: true, items: toBeDeleted });
  };

  const handleCloseConfirmation = () => {
    setDeleteDialog({ open: false, items: [] });
  };

  const deleteItems = () => {
    let toBeDeleted = deleteDialog.items;
    handleCloseConfirmation();
    handleDelete(toBeDeleted);
    setSelected([]);
  };

  const checkSelected = (id) => {
    return selected.indexOf(id) !== -1;
  };

  const numSelected = selected.length;
  const rowCount = data.length;
  return (
    <Paper className={classes.root}>
      <EnhancedTableToolbar
        handleFilter={handleFilter}
        data={data}
        numSelected={numSelected}
        selected={selected}
        handleDeleteButton={handleDeleteButton}
        handleExport={handleExport}
        handleOpenSorting={handleOpenSorting}
      />
      <Grid container spacing={3} style={{padding: '30px'}}>
        {data &&
          data.map((row) => {
            return (
              <Grid xs={3}>
               <p style={{textAlign: 'center'}}>
                 {row.day}/{row.hour}
               </p>
              </Grid>
            );
          })}
      </Grid>
    </Paper>
  );
}

export default withStyles(useStyles)(SchedulesListing);
