import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/InputFields/TextInput";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import Fields from "./Fields";
import { transformObjectToFromData } from "../../helpers/General";
import { object } from "yup/lib/locale";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty = {
  name: "",
};

const MODULE_URL = "admin/roles";

function RolesFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();
  const [item, setItem] = useState(empty);
  const [isFieldsOpen, setIsFieldsOpen] = useState(0);
  const [appModule, setAppModule] = useState(0);
  const [permission, setPermission] = useState(0);
  const [rolePermissions, setRolePermissions] = useState(empty);
  const [permissions, setPermissions] = useState([]);
  const [appModules, setAppModules] = useState([]);

  const context = useContext(AppContext);
  const {
    themeObject,
    changeLoading,
    showSnack,
    changeRefreshing,
    checkAuthorization,
  } = context;
  const { id, isOpen, handleSuccess } = props;

  const getPermissions = () => {
    api
      .get("admins/permissions/permissionsForAdmins")
      .then((response) => {
        let data = response.data.data;
        setPermissions(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAppModules = () => {
    api
      .get("admin/app_modules/app_modulesForAdmins")
      .then((response) => {
        let data = response.data.data;
        setAppModules(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getModulesPermissions = () => {
    api
      .get("admins/permissions/modulesPermissionsForAdmins")
      .then((response) => {
        let data = response.data.data;
        setRolePermissions(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isOpen && permissions.length <= 0) {
      getPermissions();
      getModulesPermissions();
      getAppModules();
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          const role = res.data.data.role;
          const permissions = res.data.data.permissions;
          setItem(role);
          setRolePermissions(permissions);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setItem(empty);
      setRolePermissions([]);
      setPermissions([]);
    }
  }, [isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const transformPermissions = (rolePermissions) => {
    rolePermissions = Object.entries(rolePermissions);
    let newData = [];
    rolePermissions.map((rolePermission) => {
      let module_id = appModules.filter(
        (module) => module.name == rolePermission[0]
      )[0]["id"];
      let items = Object.entries(rolePermission[1]);
      items.map((item) => {
        let permission_id = permissions.filter(
          (permisson) => permisson.name == item[0]
        )[0]["id"];
        newData.push({
          permission_id: permission_id,
          can: item[1]["can"],
          fields: item[1]["fields"],
          app_module_id: module_id,
        });
      });
    });
    return newData;
  };

  const handleSubmit = () => {
    changeRefreshing(true);
    const form = formRef.current;
    const { isValid, values, setSubmitting, setErrors, errors, validateForm } =
      form;
    validateForm();
    if (!isValid) {
      Object.keys(errors).map((key) => {
        showSnack(`${key}: ${errors[key]}`, "error");
      });
      console.log(errors);
      return;
    }
    setSubmitting(true);
    let method = "POST";
    let url = `${MODULE_URL}`;
    if (id) {
      values._method = "PUT";
      url += "/" + id;
    }
    if (!values.password || values.password == "") {
      delete values["password"];
    }
    values.guard_name = "admins";
    const data = transformObjectToFromData(values);
    let transformed = transformPermissions(rolePermissions);
    data.append("permissions", JSON.stringify(transformed));
    api({ method, url, data })
      .then((res) => {
        changeRefreshing(false);
        setSubmitting(false);
        handleSuccess();
      })
      .catch((err) => {
        changeRefreshing(false);
        setSubmitting(false);
        console.log("error", err);
        if (err.response) {
          console.log(err.response.data);
          setErrors(err.response.data);
        }
      });
  };
  const handleOpenFields = (module, permission) => {
    setIsFieldsOpen(1);
    setAppModule(module);
    setPermission(permission);
  };
  const isChecked = (permission, module) => {
    return (
      rolePermissions &&
      Object.keys(rolePermissions).length > 0 &&
      rolePermissions[module] !== undefined &&
      rolePermissions[module][permission] !== undefined &&
      rolePermissions[module][permission]["can"] == 1
    );
  };
  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <>
      <div>
        <Dialog
          fullScreen
          open={props.isOpen}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={props.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="subtitle2"
                className={classes.flex}
                style={{
                  color: headerTextColor,
                }}
              >
                {id ? "Update" : "Create"}
              </Typography>
              <Button
                style={{
                  color: headerTextColor,
                }}
                disabled={isDisabled()}
                onClick={handleSubmit}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <Formik
              enableReinitialize={true}
              innerRef={formRef}
              initialValues={item}
              validationSchema={Yup.object({
                name: Yup.string()
                  .max(15, "Must be 15 characters or less")
                  .required("Required"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                await new Promise((r) => setTimeout(r, 500));
                setSubmitting(false);
              }}
            >
              <form>
                <Grid container spacing={3}>
                  <TextInput
                    name="name"
                    label="Please enter role name"
                    grid={12}
                  />
                </Grid>
                <Grid container direction="row" spacing={3}>
                  {appModules &&
                    appModules.length > 0 &&
                    appModules.map((appModule) => {
                      return (
                        <Grid container direction="row" spacing={3}>
                          <Grid item xs={12}>
                            <h4>{appModule.name}</h4>
                          </Grid>
                          {permissions &&
                            permissions.length > 0 &&
                            permissions.map((permission) => {
                              if (
                                checkAuthorization(
                                  permission.name,
                                  appModule.name
                                )
                              ) {
                                return (
                                  <Grid item xs={2}>
                                    <FormControlLabel
                                      className={classes.checkBoxInput}
                                      control={
                                        <Checkbox
                                          checked={isChecked(
                                            permission.name,
                                            appModule.name
                                          )}
                                          onChange={(event) => {
                                            let newRolePermissions = {
                                              ...rolePermissions,
                                            };
                                            if (
                                              isChecked(
                                                permission.name,
                                                appModule.name
                                              )
                                            ) {
                                              newRolePermissions[
                                                appModule.name
                                              ][permission.name]["can"] = 0;
                                            } else {
                                              newRolePermissions[
                                                appModule.name
                                              ][permission.name]["can"] = 1;
                                            }
                                            setRolePermissions(
                                              newRolePermissions
                                            );
                                          }}
                                          color="primary"
                                        />
                                      }
                                      label={permission.name
                                        .toString()
                                        .replaceAll("_", " ")}
                                    />
                                    {permission.name !== "delete" && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          color: "#fff",
                                        }}
                                        onClick={() =>
                                          handleOpenFields(
                                            appModule,
                                            permission
                                          )
                                        }
                                      >
                                        Fields
                                      </Button>
                                    )}
                                  </Grid>
                                );
                              }
                            })}
                        </Grid>
                      );
                    })}
                </Grid>
              </form>
            </Formik>
          </div>
        </Dialog>
      </div>
      {Object.keys(rolePermissions).length > 0 && appModule.name && (
        <Fields
          handleClose={() => setIsFieldsOpen(0)}
          handleSuccess={(fields) => {
            let newRolePermissions = {
              ...rolePermissions,
            };
            newRolePermissions[appModule.name][permission.name]["fields"] =
              fields;
            setRolePermissions(newRolePermissions);
            setIsFieldsOpen(0);
          }}
          open={isFieldsOpen}
          module_id={appModule.id}
          fields={rolePermissions[appModule.name][permission.name]["fields"]}
        />
      )}
    </>
  );
}

export default RolesFormDialog;
