import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import { DateTimePicker } from "@material-ui/pickers";
import { KeyboardTimePicker } from "@material-ui/pickers";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { api } from "../constants/Constants";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   margin: theme.spacing(),
  //   minWidth: 120,
  // },
  root3: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  table: {
    minWidth: 600,
  },
  dialog: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

function Filters(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [filters, setFilters] = useState(null);
  const [allFilters, setAllFilters] = useState([]);
  const { handleFilter, url } = props;
  const location = useLocation();
  useEffect(() => {
    if (open && url != currentUrl) {
      getFilters();
    }
    if (url != currentUrl) {
      handleClear();
    }
  }, [url, open]);

  const getFilters = () => {
    api
      .get(url)
      .then((res) => {
        let data = [];
        res.data.data &&
          res.data.data.length > 0 &&
          res.data.data.map((item) => {
            if (
              !location.pathname.includes("ClassProgram") &&
              item.relation !== "classProgram"
            ) {
              data.push(item);
            } else if (
              !location.pathname.includes("Coach") &&
              item.relation !== "coach"
            ) {
              data.push(item);
            }
          });

        setAllFilters(data);
        setCurrentUrl(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReset = () => {
    setFilters([]);
    handleFilter([]);
  };

  const handleInputChange = (index, event) => {
    let value = event.target.value;
    let newAllFilters = [...allFilters];
    newAllFilters[index]["value"] = value;
    setAllFilters(newAllFilters);
  };

  const handleFirstDateTimeChange = (index, date) => {
    let value = moment(date).format();
    let newAllFilters = [...allFilters];
    if (!newAllFilters[index]["value"]) {
      newAllFilters[index]["value"] = new Array(2);
    }
    newAllFilters[index]["value"][0] = value;
    setAllFilters(newAllFilters);
  };

  const handleSecondDateTimeChange = (index, date) => {
    let value = moment(date).format();
    let newAllFilters = [...allFilters];
    if (!newAllFilters[index]["value"]) {
      newAllFilters[index]["value"] = new Array(2);
    }
    newAllFilters[index]["value"][1] = value;
    setAllFilters(newAllFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderValueField = (index, item) => {
    switch (item.type) {
      case "text":
        return (
          <TextField
            label="Value"
            value={item.value}
            onChange={(event) => handleInputChange(index, event)}
          />
        );
      case "select":
        return (
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Select State
            </InputLabel>
            <Select
              value={item.value}
              onChange={(event) => handleInputChange(index, event)}
              displayEmpty
              className={classes.selectEmpty}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {item.options.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "datetime":
        switch (item.operator) {
          case "between":
            return (
              <view>
                <Grid>
                  <InputLabel>Date</InputLabel>
                  <DateTimePicker
                    name="datetime"
                    value={item.value ? item.value[0] : null}
                    fullWidth
                    onChange={(date) => handleFirstDateTimeChange(index, date)}
                  />
                </Grid>
                <Grid>
                  <InputLabel>Date</InputLabel>
                  <DateTimePicker
                    name="datetime"
                    value={item.value ? item.value[1] : null}
                    fullWidth
                    onChange={(date) => handleSecondDateTimeChange(index, date)}
                  />
                </Grid>
              </view>
            );
        }
    }
  };

  const handleClear = () => {
    let newAllFilters = [...allFilters];
    newAllFilters.map((index, item) => {
      index.value = "";
      index.operator = "";
    });
    setAllFilters(newAllFilters);
    setFilters([]);
    handleFilter([]);
  };

  const handleChangeOperator = (index, event) => {
    let value = event.target.value;
    let newAllFilters = [...allFilters];
    newAllFilters[index]["operator"] = value;
    setAllFilters(newAllFilters);
  };

  const submitFilters = () => {
    let results = [];
    if (allFilters && allFilters.length > 0) {
      results = allFilters.filter((item) => {
        return (
          item.value !== null &&
          item.value !== "" &&
          item.operator !== null &&
          item.operator !== ""
        );
      });
      // allFilters.map((item) => {
      //   if (item.value !== null && item.operator !== null) {
      //     results.push(item);
      //   }
      // });
    }
    setFilters(results);
    handleFilter(results);
    handleClose();
  };

  return (
    <div>
      <Grid
        style={{ width: "20vw", display: "flex", justifyContent: "flex-end" }}
      >
        <IconButton aria-label="Filter" onClick={handleClickOpen}>
          <FilterListIcon color="primary" />
        </IconButton>
        {filters && filters.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            style={{
              margin: "30px 15px",
              color: "#fff",
            }}
            disabled={false}
            onClick={handleClear}
          >
            Clear
          </Button>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Filter
        </DialogTitle>

        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Operator</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allFilters.map((item, index) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle1" gutterBottom>
                        {item.label}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-placeholder-label-label"
                        >
                          Select Operator
                        </InputLabel>
                        <Select
                          value={item.operator}
                          onChange={(event) =>
                            handleChangeOperator(index, event)
                          }
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {item.operators.map((operator) => (
                            <MenuItem value={operator}>{operator}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell align="left">
                      {renderValueField(index, item)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear} color="primary">
            Clear
          </Button>
          <Button onClick={submitFilters} color="primary" autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Filters;
