import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";
import MultiSelectInput from "../../components/InputFields/MultiSelectInput";

import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import Download from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  name: "",
  intro: "",
  description: "",
  email: "",
  phone: "",
  brochure: "",
  location_link: "",
  website_link: "",
  is_active: 1,
  listing_image: empty_image,
  cover_image: empty_image,
  master_plan_image: empty_image,
  gallery_image: [],
  propertyTypes: "",
  facilities: "",
};

const MODULE_URL = "admin/projects";

function ProjectsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [selectIconOptions, setSelectIconOptions] = useState([]);
  const [selectPropertyOptions, setSelectPropertyOptions] = useState([]);
  const [file, setFile] = useState("");

  let facilities = props.facilitiesList;
  let properties = props.properties;
  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          if (tmp.email) {
            tmp.email = tmp.email.split(",");
          }
          setItem(tmp);
          setFile(tmp.brochure);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
      setFile("");
    }
  }, [id, isOpen]);
  useEffect(() => {
    let selectedFacilities = [];
    if (item.facilities) {
      facilities.map((value) => {
        item.facilities.map((icon) => {
          if (icon.id == value.value) {
            selectedFacilities.push(value);
          }
        });
      });
    }
    setSelectIconOptions(selectedFacilities);
  }, [item.facilities]);
  useEffect(() => {
    let selectedProperties = [];
    if (item.property_types) {
      properties.map((value) => {
        item.property_types.map((icon) => {
          if (icon.id == value.value) {
            selectedProperties.push(value);
          }
        });
      });
    }
    setSelectPropertyOptions(selectedProperties);
  }, [item.property_types]);

  let selectedOptions = selectIconOptions.map((t) => t.value);
  let selectedPropertiesOptions = selectPropertyOptions.map((t) => t.value);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };
  const handleFileUpload = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(256, "Must be 256 characters or less")
                .required("Required"),
            })}
            onSubmit={async (values, {setSubmitting, setErrors, setFieldError}) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;

              const input = { ...values, brochure: file ? file : null };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                  setErrors(err.response.data);
                }
                });
            }}
          >
            {(props) => {
              const { errors, values, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="name"
                      label="Please enter project name"
                      grid={6}
                    />
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="intro"
                      label="Please enter subtitle"
                      grid={6}
                    />
                    {/* <TextInput
                      name="email"
                      label="Please enter email"
                      grid={6}
                    /> */}
                    <TextInput
                      name="phone"
                      label="Please enter phone"
                      grid={6}
                    />
                    <UrlInput
                      name="location_link"
                      label="Please enter the google maps location"
                      grid={6}
                    />
                    <UrlInput
                      name="website_link"
                      label="Please enter the website"
                      grid={6}
                    />
                    <Grid container>
                      <FieldArray
                        name="email"
                        render={(arrayHelpers) => (
                          <>
                            {values.email &&
                              values.email.length > 0 &&
                              values.email.map((item, index) => (
                                <React.Fragment key={index}>
                                  <TextInput
                                    name={`email.${index}`}
                                    label="Please enter email"
                                    grid={6}
                                  />
                                  <Grid item xs={6}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      Remove email
                                    </Button>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add email
                            </Button>
                          </>
                        )}
                      />
                    </Grid>

                    {/* <TextInput
                      grid={6}
                    name="file"
                    type="file"
                    onChange={(event) =>handleFileUpload(event)}

                    /> */}

                    {file ? (
                      <>
                        {item.brochure == file ? (
                          <>
                            <Grid item xs={3}>
                              <label
                                for="file"
                                style={{
                                  marginRight: "1vw",
                                  marginLeft: "1vw",
                                  marginTop: "2vw",
                                  marginBottom: "1vw",
                                }}
                              >
                                View uploaded brochure:
                              </label>
                            </Grid>
                            <Grid item xs={6}>
                              <a href={api + item.brochure} target="__blank">
                                <Download />
                              </a>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={3}>
                              <label
                                for="file"
                                style={{
                                  marginRight: "1vw",
                                  marginLeft: "1vw",
                                  marginTop: "2vw",
                                  marginBottom: "1vw",
                                }}
                              >
                                File {file.name} uploaded:
                              </label>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={6}>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => setFile("")}
                          >
                            Remove brochure
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <label
                          for="file"
                          style={{
                            marginRight: "1vw",
                            marginLeft: "1vw",
                            marginTop: "2vw",
                            marginBottom: "1vw",
                          }}
                        >
                          File brochure upload
                        </label>
                        <input
                          id="file"
                          name="file"
                          type="file"
                          onChange={(event) => handleFileUpload(event)}
                          className="form-control"
                          style={{
                            marginTop: "2vw",
                            marginBottom: "1vw",
                          }}
                        />
                      </>
                    )}

                    <TranslatableTextAreaInput
                      translations={item.allTranslations}
                      name="description"
                      label="Description"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <MultiSelectInput
                      name="facilities"
                      label="Select Facilities"
                      grid={6}
                      options={facilities}
                      closeMenuOnSelect={false}
                      setFieldValue={setFieldValue}
                      value={selectedOptions}
                    />
                    <MultiSelectInput
                      name="property_types"
                      label="Select property types"
                      grid={6}
                      options={properties}
                      closeMenuOnSelect={false}
                      setFieldValue={setFieldValue}
                      value={selectedPropertiesOptions}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <ImageInput
                      name="listing_image"
                      label="Listing image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <ImageInput
                      name="cover_image"
                      label="Cover image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <ImageInput
                      name="master_plan_image"
                      label="Master plan"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    {/* <GalleryInput
                      name="gallery"
                      label="Gallery Images"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    /> */}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ProjectsFormDialog;
