import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import routes from "./routes/routes";
import Layout from "./components/Layout/Layout";
import MiddlewareRoute from "./components/MiddlewareRoute";
import Login from "./pages/Login/Login";
import AppContext, { Provider } from "./context/AppContext";
import Loader from "./components/Loader";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "react-sortable-tree/style.css";

function App() {
  return (
    <Provider>
      <AppContext.Consumer>
        {({ isLogged, appReady }) => {
          if (appReady) {
            return (
              <Router>
                {!!isLogged ? (
                  <Layout>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Switch>
                        {routes.map((route, index) => (
                          <MiddlewareRoute
                            key={index}
                            exact={route.exact}
                            path={route.path}
                            permission={route.permission}
                            render={(props) => <route.component {...props} />}
                          />
                        ))}
                        <Redirect to="/" />
                      </Switch>
                    </MuiPickersUtilsProvider>
                  </Layout>
                ) : (
                  <Switch>
                    <Route path="/" component={Login} exact />
                    <Redirect to="/" />
                  </Switch>
                )}
              </Router>
            );
          } else {
            return <Loader />;
          }
        }}
      </AppContext.Consumer>
    </Provider>
  );
}

export default App;
