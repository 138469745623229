import axios from "axios";
import { getValidToken } from "../auth/Auth";

// get api url from admin url
let apiSubDomain = window.location.host.substring(
  0,
  window.location.host.lastIndexOf("-")
);

if (apiSubDomain.endsWith("-club")) {
  apiSubDomain += "-api";
}

const apiDomainAsArray = window.location.host.split(".");
apiDomainAsArray.shift();

const apiAutoBaseURL = `${
  window.location.protocol
}//${apiSubDomain}.${apiDomainAsArray.join(".")}/`;

// still you have option to custom API url
export const API_URL = process.env.REACT_APP_BASE_URL ?? apiAutoBaseURL;

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("jwtToken"),
};

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(function (config) {
  const token = getValidToken();
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

// api.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const token = localStorage.getItem("jwtToken");
//       if (token) {
//         return axios
//           .post(
//             API_URL + "admins/auth/refresh",
//             {},
//             {
//               headers: {
//                 Authorization: "Bearer " + token,
//               },
//             }
//           )
//           .then((res) => {
//             if (res.status === 200) {
//               localStorage.setItem("", res.data.data);
//               axios.defaults.headers.common["Authorization"] =
//                 "Bearer " + localStorage.getItem("jwtToken");
//               return axios(originalRequest);
//             }
//           })
//           .catch((err) => {
//             return Promise.reject(error);
//           });
//       } else {
//         return Promise.reject(error);
//       }
//     }
//     return Promise.reject(error);
//   }
// );
export { api };
