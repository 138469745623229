import React, { useContext, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import PrivateTrainingRequestsFilters from "./PrivateTrainingRequestsFilters";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import SortIcon from "@material-ui/icons/Sort";
import AppContext from "../../context/AppContext";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LaunchIcon from "@material-ui/icons/Launch";
import ViewIcon from "@material-ui/icons/Visibility";
import FormSubmissionsFormDialog from "../FormSubmissions/FormSubmissionsFormDialog";
import Filters from "../../components/Filters";

const MODULE_FILTERS_URL = "/admin/private-training-requests/filters";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
  root2: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root3: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
});

const useStyles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  tableCellStyles: {
    padding: "15px",
  },
  resendButton: {
    marginLeft: theme.spacing(2),
  },
});

function EnhancedTableToolbar(props) {
  const {
    classes,
    handleFilter,
    numSelected,
    handleExport,
    handleOpenSorting,
    selected,
  } = props;
  return (
    <div>
      <Toolbar className={classNames(classes.root)}>
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            Private Training Requests
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip title="Filters">
            <Filters handleFilter={handleFilter} url={MODULE_FILTERS_URL} />
          </Tooltip>
          <Tooltip title="Sort">
            <IconButton aria-label="Sort" onClick={handleOpenSorting}>
              <SortIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="export all">
            <IconButton aria-label="export all" onClick={handleExport}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          {/* {numSelected > 0 && (
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                onClick={() => props.handleDeleteButton(selected)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )} */}
        </div>
      </Toolbar>
    </div>
  );
}
EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

function PrivateTrainingRequestsListing(props) {
  const [selected, setSelected] = useState([]);
  const [sorting, setSorting] = useState({
    order: "desc",
    orderBy: "id",
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    items: [],
  });
  const [itemDialog, setItemDialog] = useState({
    item: null,
    open: false,
    type: null,
  });
  const context = useContext(AppContext);
  const { showSnack, checkAuthorization } = context;

  const {
    handleDelete,
    classes,
    data,
    handleOpenEditFrom,
    handleFilter,
    handleExport,
    handleOpenSorting,
    handleStatusChange,
  } = props;
  const handleRequestSort = (event, property) => {
    const tempOrderBy = property;
    let tempOrder = "desc";
    const { orderBy, order } = sorting;
    if (orderBy === property && order === "desc") {
      tempOrder = "asc";
    }
    setSorting({ order: tempOrder, orderBy: tempOrderBy });
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      setSelected(props.data.map((n) => n.id));
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const tempSelected = [...selected];
    const selectedIndex = tempSelected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(tempSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(tempSelected.slice(1));
    } else if (selectedIndex === tempSelected.length - 1) {
      newSelected = newSelected.concat(tempSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        tempSelected.slice(0, selectedIndex),
        tempSelected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleDeleteButton = (toBeDeleted) => {
    if (!checkAuthorization("delete", "PrivateTrainingRequests")) {
      showSnack(
        "You don't have permission to delete private_training_requests",
        "error"
      );
    } else {
      handleOpenConfirmation(toBeDeleted);
    }
  };

  const handleOpenConfirmation = (toBeDeleted) => {
    setDeleteDialog({ open: true, items: toBeDeleted });
  };

  const handleCloseConfirmation = () => {
    setDeleteDialog({ open: false, items: [] });
  };

  const deleteItems = () => {
    let toBeDeleted = deleteDialog.items;
    handleCloseConfirmation();
    handleDelete(toBeDeleted);
    setSelected([]);
  };

  const checkSelected = (id) => {
    return selected.indexOf(id) !== -1;
  };

  const handleOpenPassportFrom = (id, type) => {
    if (!checkAuthorization("update", "MovePassportSubmissions")) {
      showSnack("You don't have permission to edit submissions", "error");
    } else {
      setItemDialog({ item: id, open: true, type: type });
    }
  };
  const handleCloseItemsFormDialog = () => {
    setItemDialog({ item: null, open: false, type: null });
  };

  const request_status = ["approved", "rejected"];
  const numSelected = selected.length;
  const rowCount = data.length;
  return (
    <Paper className={classes.root}>
      <DeleteConfirmationDialog
        isOpen={deleteDialog.open}
        id={deleteDialog.items}
        handleCloseDeleteConfirmationDialog={handleCloseConfirmation}
        title="Deleting"
        text="Are you sure you want to perform this action?"
        handleConfirmDelete={deleteItems}
      />
      <EnhancedTableToolbar
        handleFilter={handleFilter}
        data={data}
        numSelected={numSelected}
        selected={selected}
        handleDeleteButton={handleDeleteButton}
        handleExport={handleExport}
        handleOpenSorting={handleOpenSorting}
      />
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell>ID</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Coach</TableCell>
            <TableCell>Class</TableCell>
            <TableCell>Package</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Schedule</TableCell>
            <TableCell>Move Passport</TableCell>
            <TableCell>Requested at</TableCell>
            {/*<TableCell>Updated at</TableCell>*/}
            {/*<TableCell>Activity</TableCell>*/}
            {/* <TableCell>Controls</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row) => {
              const isSelected = checkSelected(row.id);
              return (
                <TableRow
                  key={row.id}
                  hover
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      onClick={(event) => handleClick(event, row.id)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.user && row.user.full_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.status == "pending" ? (
                      <Select
                        value={row.status}
                        onChange={(event) => {
                          if (
                            !checkAuthorization(
                              "update",
                              "PrivateTrainingRequests"
                            )
                          ) {
                            showSnack(
                              "You don't have permission to edit private_training_requests",
                              "error"
                            );
                          } else {
                            handleStatusChange(
                              row.id,
                              row.location.id,
                              event.target.value
                            );
                          }
                        }}
                        displayEmpty
                        disabled={
                          !checkAuthorization(
                            "update",
                            "PrivateTrainingRequests"
                          )
                        }
                        className={classes.selectEmpty}
                      >
                        <MenuItem disabled value={"pending"}>
                          {"PENDING"}
                        </MenuItem>
                        <MenuItem disabled value={"user_approved"}>
                          {"USER APPROVED"}
                        </MenuItem>
                        <MenuItem disabled value={"user_rejected"}>
                          {"USER REJECTED"}
                        </MenuItem>
                        {request_status.map((option) => (
                          <MenuItem
                            value={option}
                            disabled={
                              !checkAuthorization(
                                "update",
                                "PrivateTrainingRequests"
                              )
                            }
                          >
                            {option.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      row.status.toUpperCase()
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.coach && row.coach.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.class && row.class.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.package && row.package.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.location && row.location.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <IconButton
                      color="secondary"
                      aria-label="upload picture"
                      component="span"
                      disabled={row.status != "approved"}
                      onClick={() => {
                        console.log(
                          `/private/${row.id}/locations/${row.location.id}/schedules`
                        );
                        props.history.push(
                          `/private/${row.id}/locations/${row.location.id}/schedules`
                        );
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Tooltip title="Edit">
                      <IconButton
                        className={classes.button}
                        aria-label="Edit"
                        disabled={row.extra_form_id == null}
                        onClick={() =>
                          handleOpenPassportFrom(
                            row.extra_form_id,
                            row.subscribable_type == "class_program"
                              ? "ClassProgram"
                              : "Coach"
                          )
                        }
                      >
                        <ViewIcon color="action" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(row.created_at).format(DATE_FORMAT)}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <FormSubmissionsFormDialog
        handleClose={handleCloseItemsFormDialog}
        handleSuccess={() => {
          setItemDialog({ open: false, item: null });
        }}
        isOpen={itemDialog.open}
        id={itemDialog.item}
        type={itemDialog.type}
      />
    </Paper>
  );
}

export default withStyles(useStyles)(PrivateTrainingRequestsListing);
