import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import DateInput from "../../components/InputFields/DateInput";
import MultiSelectInput from "../../components/InputFields/MultiSelectInput";
import SelectInput from "../../components/InputFields/SelectInput";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  title: "",
  value: 0,
  max_value: 0,
  max_user: 0,
  is_active: 1,
  is_general: 1,
  type: 1,
  packages: [],
  expire_date: moment().add(3, "month"),
};

const MODULE_URL = "admin/promo-codes";

function PromoCodesFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getPackages();
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          tmp.packages = tmp.packages.map((item) => item.id);
          setItem(tmp);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getPackages = () => {
    api
      .get(`admin/packages?limit=100`)
      .then((res) => {
        setPackages(res.data.data.data);
      })
      .catch((err) => {
        changeLoading(false);
      });
  };
  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(30, "Must be 30 characters or less")
                .required("Required"),
              value: Yup.number()
                .min(1, "Must be greater than 1")
                .required("Required"),
              max_user: Yup.number()
                .min(1, "Must be greater than 1")
                .required("Required"),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              input.title = input.title.toUpperCase().replace(" ", "");
              input.max_value = input.type == 2 ? input.value : input.max_value;
              input.expire_date = moment(input.expire_date).format(DATE_FORMAT);
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  console.log(res);
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                    setErrors(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { values, setFieldError, setFieldValue, errors } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TextInput
                      name="title"
                      label="Please enter promo code title"
                      grid={12}
                    />
                    <SelectInput
                      name="type"
                      label="Select promo code type"
                      grid={12}
                      options={[
                        { label: "Percentage", value: 1 },
                        { label: "Fixed amount", value: 2 },
                      ]}
                      setFieldValue={setFieldValue}
                    />
                    <TextInput
                      name="value"
                      label="Please enter promo code value"
                      type="number"
                      grid={6}
                    />

                    {values.type == 1 && (
                      <TextInput
                        name="max_value"
                        type="number"
                        label="Please enter promo code Maximum value"
                        grid={6}
                      />
                    )}
                    <TextInput
                      name="max_user"
                      type="number"
                      label="Please enter promo code Maximum users"
                      grid={12}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Active"
                      grid={3}
                      setFieldValue={setFieldValue}
                    />
                    <SwitchInput
                      name="is_general"
                      label="General Promo Code"
                      grid={3}
                      setFieldValue={setFieldValue}
                    />
                    <DateInput
                      name="expire_date"
                      label="Expire Date"
                      grid={3}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                    />
                    {!values.is_general && (
                      <MultiSelectInput
                        name="packages"
                        label="Select filtered packages"
                        grid={6}
                        valueKey="id"
                        labelKey="title"
                        options={packages}
                        value={item.packages}
                        closeMenuOnSelect={false}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default PromoCodesFormDialog;
