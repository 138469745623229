import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";
import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import DateInput from "../../components/InputFields/DateInput";
import Select from "react-select";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 400,
    marginRight: "1vw",
    marginBottom: "1vw",
    marginTop: "2vw",
    border: "1px solid rgb(55, 17, 109, 0.5)",

    // This line disable the blue border
    boxShadow: "none",
    borderRadius: 4,
    "&:hover": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
      borderRadius: 4,
    },
  }),
};
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD";

const empty = {
  name: "",
  is_active: 1,
  cover_image: empty_image,
  date: moment(new Date()).add(1, "d").format(DATE_FORMAT),
  state: null,
  user_id: null,
  email: "",
  username: "",
  phone: "",
};

const MODULE_URL = "admin/services_requests";

function ServicesRequestsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const [state, setState] = useState(0);
  const [category, setCategory] = useState("");
  const [timeRange, setTimeRange] = useState("");
  const [serviceItem, setServiceItem] = useState("");
  const [user, setUser] = useState("");
  const [currentCategory, setCurrentCategory] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [isSelectUserOpen, setIsSelectUserOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [users, setUsers] = useState([]);
  const [userUnits, setUserUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  let categories = [];
  props.categories.map((item) =>
    categories.push({
      value: item.id,
      label: item.name,
      times: item.time_ranges,
      items: item.services_item,
    })
  );

  // let allUsers = [];
  // if (props.users && props.users.length > 0) {
  //   props.users.map((item) =>
  //     allUsers.push({
  //       value: item.id,
  //       label: item.first_name + " " + item.last_name,
  //     })
  //   );
  // }

  let itemsService = [];
  let timeRanges = [];
  if (category) {
    if (category.items && category.items.length > 0) {
      category.items.map((service) =>
        itemsService.push({
          value: service.id,
          label: service.name,
          category_id: service.category_id,
          price: service.price,
        })
      );
    }
    // else {
    //   setServiceItem({
    //     value: category.items.id,
    //     label: category.items.name,
    //     category_id: category.items.category_id,
    //     price: category.items.price,
    //   });
    // }
    if (category.times && category.times.length > 0) {
      category.times.map((time) => {
        timeRanges.push({
          value: time.id,
          label: time.from + " " + time.to,
        });
      });
    }
    //  else {
    //   setTimeRange({
    //     value: category.times.id,
    //     label: category.times.from + " " + category.times.to,
    //   });
    // }
  }

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          setItem(tmp);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
      setCategory("");
      setTimeRange("");
      setServiceItem("");
    }
  }, [id, isOpen]);
  useEffect(() => {
    if (item.servicecategory) {
      setCategory({
        value: item.servicecategory ? item.servicecategory.id : "",
        label: item.servicecategory ? item.servicecategory.name : "",
        times: item.time_range,
        items: item.serviceitem,
      });

      setTimeRange({
        value: item.time_range ? item.time_range.id : "",
        label: item.time_range
          ? item.time_range.from + " " + item.time_range.to
          : "",
      });
      setServiceItem({
        value: item.serviceitem ? item.serviceitem.id : "",
        label: item.serviceitem ? item.serviceitem.name : "",
        category_id: item.serviceitem ? item.serviceitem.item_id : "",
        price: item.serviceitem ? item.serviceitem.price : "",
      });
    }
  }, [item.servicecategory]);

  useEffect(() => {
    let units = [];
    if (user && user.units.length > 0) {
      units = user.units.map(
        (item) =>
          (item = {
            id: item.id,
            label: item.unit_code,
            project_id: item.project_id,
          })
      );

      setUserUnits(units);
    }
  }, [user.units]);

  useEffect(() => {
    let state = 0;
    if (item.state == 0) {
      state = { value: 0, label: "Pending" };
      setState(state);
    } else if (item.state == 1) {
      state = { value: 1, label: "inProgress" };
      setState(state);
    } else if (item.state == 2) {
      state = { value: 2, label: "Fullfilled" };
      setState(state);
    } else if (item.state == 3) {
      state = { value: 3, label: "Cancel By User" };
      setState(state);
    } else if (item.state == 4) {
      state = { value: 4, label: "Cancel By Admin" };
      setState(state);
    }
  }, [item.state]);
  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };
  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };
  const selectOptions = [
    { value: 0, label: "Pending" },
    { value: 1, label: "inProgress" },
    { value: 2, label: "Fullfilled" },
    { value: 3, label: "Cancel By User" },
    { value: 4, label: "Cancel By Admin" },
  ];

  const headerTextColor = themeObject.headerText ?? "#fff";
  const handleSearchChange = (value) => {
    setSearching(true);
    api
      .get(`admin/users/search?query=${value}`)
      .then((response) => {
        let searchedUsers = [];
        if (response.data.data && response.data.data.length > 0) {
          searchedUsers = response.data.data.map(
            (item) =>
              (item = {
                id: item.id,
                label: [
                  item.full_name,
                  " ( " + item.phone + " ) ",
                  item.email ? "(" + item.email + ")" : "",
                ],
                units: item.active_units,
              })
          );
        }
        setUsers(searchedUsers);
        setSearching(false);
      })
      .catch((err) => {
        setSearching(false);
        setUsers([]);
      });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            // validationSchema={Yup.object({
            //   name: Yup.string()
            //     .max(256, "Must be 256 characters or less")
            //     .required("Required"),
            // })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                ...values,
                user_id: item.user_id ? item.user_id : user.id,
                unit_id: item.unit_id ? item.unit_id : selectedUnit.id,
                service_category_id: serviceItem.category_id
                  ? serviceItem.category_id
                  : item.service_category_id,
                service_item_id: serviceItem.value,
                time_range: timeRange.value,
                state: item.state ? item.state : selectedState.value,
              };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                    setErrors(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    {/* {item.user_id == null && (
                      <Autocomplete
                        id="user_id"
                        name="user_id"
                        options={users}
                        getOptionLabel={(option) => option.label}
                        style={{ width: 300 }}
                        onChange={(e, value) => {
                          console.log(value);
                          setFieldValue(
                            "user_id",
                            value !== null ? value.id : item.user_id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            margin="normal"
                            label="Select User"
                            fullWidth
                            {...params}
                            onChange={handleSearchChange}
                          />
                         
                        )}
                      />
                    )} */}
                    {item.user_id == null ? (
                      <Select
                        placeholder="Select User"
                        label="Select User"
                        id="color"
                        options={users}
                        styles={customStyles}
                        closeMenuOnSelect={true}
                        value={user}
                        onChange={(value) => setUser(value)}
                        onInputChange={(value) => handleSearchChange(value)}
                      />
                    ) : (
                      <>
                        <TextInput
                          name="username"
                          label={
                            item.user.first_name + " " + item.user.last_name
                          }
                          grid={3}
                          disabled={true}
                        />
                        <TextInput
                          name="phone"
                          label={item.user.phone}
                          grid={3}
                          disabled={true}
                        />
                        <TextInput
                          name="email"
                          label={item.user.email ? item.user.email : "N/A"}
                          grid={3}
                          disabled={true}
                        />
                      </>
                    )}

                    {user && (
                      <Select
                        placeholder="User Units"
                        label="Select Unit"
                        id="color"
                        options={userUnits}
                        styles={customStyles}
                        closeMenuOnSelect={true}
                        value={selectedUnit}
                        onChange={setSelectedUnit}
                      />
                    )}
                    <Select
                      placeholder="Categories"
                      label="Select Category"
                      id="color"
                      options={categories}
                      styles={customStyles}
                      closeMenuOnSelect={true}
                      value={category}
                      onChange={setCategory}
                    />
                    <Select
                      placeholder="Service"
                      label="Select Service"
                      id="color"
                      options={itemsService}
                      styles={customStyles}
                      closeMenuOnSelect={true}
                      value={serviceItem}
                      onChange={setServiceItem}
                    />
                    <Select
                      placeholder="Time Range"
                      label="Select Time Range"
                      id="color"
                      options={timeRanges}
                      styles={customStyles}
                      closeMenuOnSelect={true}
                      value={timeRange}
                      onChange={setTimeRange}
                    />
                    <Grid item xs={6}>
                      <DateInput
                        name="date"
                        label="Request Date"
                        grid={6}
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        errors={errors}
                      />
                    </Grid>

                    {/* <SelectInput
                      name="state"
                      label="Select State"
                      grid={12}
                      options={selectOptions}
                    /> */}
                    {/* <Select
                      name="state"
                      label="Select State"
                      id="color"
                      options={selectOptions}
                      styles={customStyles}
                      closeMenuOnSelect={true}
                      value={selectedState}
                      onChange={setSelectedState}
                      placeholder="Select State"
                    /> */}

                    {state.value == 3 && (
                      <TextAreaInput
                        name="user_cancel_reason"
                        label="User Cancel Reason"
                        grid={12}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <TextAreaInput
                      name="user_message"
                      label="User Message"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <TextAreaInput
                      name="admin_notes"
                      label="Admin Notes"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    {state.value == 4 && (
                      <TextAreaInput
                        name="admin_cancel_reason"
                        label="Admin Cancel Reason"
                        grid={12}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <Grid item xs={12}>
                      <Select
                        name="state"
                        label="Select State"
                        id="color"
                        options={selectOptions}
                        styles={customStyles}
                        closeMenuOnSelect={true}
                        value={selectedState ? selectedState : state}
                        onChange={setSelectedState}
                        placeholder="Select State"
                        grid={12}
                      />
                    </Grid>
                    {item.file != null && (
                      <Grid item xs={6}>
                        <img src={item.file.url} />
                      </Grid>
                    )}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ServicesRequestsFormDialog;
