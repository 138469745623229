import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MultiSelectInput from "../../components/InputFields/MultiSelectInput";
import ProjectInput from "../../components/InputFields/ProjectsInput";

const animatedComponents = makeAnimated();

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 400,
    border: "1px solid rgb(55, 17, 109, 0.5)",
    // This line disable the blue border
    boxShadow: "none",
    borderRadius: "4px",

    "&:hover": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
    },
    "&:focus": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
    },
  }),
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  name: "",
  is_active: 1,
  cover_image: empty_image,
  icon_image: empty_image,
  // working_days: [],
  parent_id: 0,
  gallery_image: [],
  time_ranges: [],
  description: "",
  email: "",
};

const MODULE_URL = "admin/services_categories";

function ServicescategoriesFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectTimeOptions, setSelectTimeOptions] = useState([]);
  const [parentID, setParentID] = useState(0);
  let timeRanges = props.timeRanges
    ? props.timeRanges.map((item) => ({
        label: `${item.from} - ${item.to}`,
        value: item.id,
      }))
    : [];

  let categories = props.categories.map(({ id, name }) => {
    if (item.id === id) {
      return { value: id, label: name, isDisabled: true };
    } else {
      return {
        value: id,
        label: name,
      };
    }
  });
  useEffect(() => {
    if (item.parent && item.parent !== null && item.parent !== undefined) {
      let parent = [{ value: item.parent.id, label: item.parent.name }];
      setParentID(parent);
    }
  }, [item.parent_id]);

  useEffect(() => {
    let times = [];
    let selectedIDs = [];
    item.time_ranges.map((item) => selectedIDs.push(item.id));

    if (selectedIDs) {
      timeRanges.map((value) => {
        if (selectedIDs.includes(value.value)) {
          times.push(value);
        }
      });
    }
    setSelectTimeOptions(times);
  }, [item.time_ranges]);

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          if (tmp.email) {
            tmp.email = tmp.email.split(",");
          }
          setItem(tmp);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
      setParentID([]);
      setSelectTimeOptions([]);
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";

  let selectedOptions = selectTimeOptions.map((t) => t.value);
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid rgb(55, 17, 109, 0.5)",
      // This line disable the blue border
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgb(55, 17, 109, 0.5)",
      },
    }),
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(256, "Must be 256 characters or less")
                .required("Required"),
            })}
            onSubmit={async (values, {setSubmitting, setErrors, setFieldError}) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                ...values,
                parent_id: parentID.value
                  ? parentID.value
                  : props.parent_id
                  ? props.parent_id
                  : 0,
              };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }

              console.log("input", input);
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                  setErrors(err.response.data);
                }
                });
            }}
          >
            {(props) => {
              const { errors, values, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <ProjectInput
                      setFieldValue={setFieldValue}
                      value={item.related_projects}
                    />
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="name"
                      label="Please enter category name"
                      grid={6}
                    />
                    <Grid container spacing={12} xs={12}>
                      <Select
                        placeholder="Please select parent"
                        id="color"
                        options={categories}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={parentID}
                        onChange={setParentID}
                      />
                    </Grid>
                    {item.childs_count == 0 && (
                      <Grid container spacing={12} xs={12}>
                        <MultiSelectInput
                          name="time_ranges"
                          label="Select time ranges"
                          grid={12}
                          options={timeRanges}
                          closeMenuOnSelect={false}
                          setFieldValue={setFieldValue}
                          value={selectedOptions}
                        />
                      </Grid>
                    )}
                    {item.childs_count == 0 && (
                      <>
                        {/* <TextInput
                          name="email"
                          label="Please enter email"
                          grid={6}
                        /> */}
                        <Grid container>
                          <FieldArray
                            name="email"
                            render={(arrayHelpers) => (
                              <>
                                {values.email &&
                                  values.email.length > 0 &&
                                  values.email.map((item, index) => (
                                    <React.Fragment key={index}>
                                      <TextInput
                                        name={`email.${index}`}
                                        label="Please enter email"
                                        grid={6}
                                      />
                                      <Grid item xs={6}>
                                        <Button
                                          color="secondary"
                                          variant="contained"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          Remove email
                                        </Button>
                                      </Grid>
                                    </React.Fragment>
                                  ))}
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  Add email
                                </Button>
                              </>
                            )}
                          />
                        </Grid>
                        <TranslatableTextAreaInput
                          translations={item.allTranslations}
                          name="description"
                          label="Description"
                          grid={12}
                          setFieldValue={setFieldValue}
                        />
                      </>
                    )}
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <ImageInput
                      name="icon_image"
                      label="Icon Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <ImageInput
                      name="cover_image"
                      label="Cover Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    {/* <GalleryInput
                      name="gallery"
                      label="Gallery Images"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    /> */}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ServicescategoriesFormDialog;
