import * as Yup from "yup";
import { useContext } from "react";
import AppContext from "../context/AppContext";
// import AppContext from "../../context/AppContext";

export function isImage(file) {
  if (file) {
    let ValidImageTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/svg+xml",
    ];
    let found = ValidImageTypes.indexOf(file["type"]);
    return found === -1 ? false : true;
  }
  return false;
}

export function isicon(file) {
  if (file) {
    let ValidiconTypes = ["image/gif", "image/jpeg", "image/png"];
    let found = ValidiconTypes.indexOf(file["type"]);
    return found === -1 ? false : true;
  }
  return false;
}

export function isDocument(file) {
  if (file) {
    let ValidImageTypes = [
      "text/plain",
      "application/msword",
      "application/pdf",
    ];
    // let found = ValidImageTypes.indexOf(file["type"]);
    // return found === -1 ? false : true;
    return ValidImageTypes.indexOf(file["type"]) !== -1;
  }
  return false;
}

export function isEmail(value) {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
}

export function yupPassword(value) {
  return Yup.string().matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  );
}

export function isObject(value) {
  return typeof value === "object";
}

export function yupRequiredTranslatableString(langs = null, required = true) {
  if (langs === null) {
    langs = JSON.parse(localStorage.getItem("languages")).map(
      (lang) => lang.code
    );
  }

  let format = "^";
  langs.map((item) => {
    format += `(?=.*"${item}":${required ? '"([^"]+)"' : ".*"})`;
    return item;
  });
  format += ".*$";

  return Yup.string().matches(
    format, // json formatting {"en": "value"}
    "Required field"
  );
}

export function yupLink() {
  return Yup.string().matches(
    "((https?):\\/\\/)?(www.)?[a-z0-9]+(\\.[a-z]{2,}){1,3}(#?\\/?[a-zA-Z0-9#-_]+)*\\/?(\\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$",
    "Enter correct url!"
  );
}

export function yupPhone() {
  return Yup.string()
    .min(4)
    .matches(
      "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$",
      "Enter correct phone!"
    );
}
