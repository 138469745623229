import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  username: "",
  projectname: "",
  comment: "",
  phone: "",
  email: "",
};

const MODULE_URL = "admin/realEstateSubmissions";

function RealEstateSubmissionsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          setItem(res.data.data);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Real Estate Submissions"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className={classes.root}>
              <Formik
                enableReinitialize={true}
                innerRef={formRef}
                initialValues={item}
                validationSchema={Yup.object({
                  name: Yup.string()
                    .max(256, "Must be 256 characters or less")
                    .required("Required"),
                })}
                onSubmit={async (values, {setSubmitting, setErrors, setFieldError}) => {
                  await new Promise((r) => setTimeout(r, 500));
                  changeRefreshing(true);
                  setSubmitting(true);
                  let method = "POST";
                  let url = `${MODULE_URL}`;
                  const input = { ...values };
                  if (id) {
                    input._method = "PUT";
                    url += "/" + id;
                  }
                  const data = transformObjectToFromData(input);
                  api({ method, url, data })
                    .then((res) => {
                      changeRefreshing(false);
                      setSubmitting(false);
                      handleSuccess();
                    })
                    .catch((err) => {
                      changeRefreshing(false);
                      setSubmitting(false);
                      console.log("error", err);
                      if (err.response) {
                        setErrors(err.response.data);
                      }
                    });
                }}
              >
                {(props) => {
                  const { errors, setFieldError, setFieldValue } = props;
                  return (
                    <form>
                      <Grid container spacing={3}>
                        <TextInput
                          name="username"
                          label="User Name"
                          grid={6}
                          disabled={true}
                        />
                        <TextInput
                          name="phone"
                          label="User Phone"
                          grid={6}
                          disabled={true}
                        />
                        <TextInput
                          name="email"
                          label="User Email"
                          grid={6}
                          disabled={true}
                        />
                        <TextInput
                          name="projectname"
                          label="Project"
                          grid={6}
                          disabled={true}
                        />
                        <TextInput
                          name="comment"
                          label="User Message"
                          grid={12}
                          disabled={true}
                          rowsMax={4}
                        />
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RealEstateSubmissionsFormDialog;
