import React, { Component, Fragment, useEffect } from "react";
import ActivityCategoriesFormDialog from "./ActivityCategoriesFormDialog";
import ActivityCategoriesListing from "./ActivityCategoriesListing";
import ActivityCategoriesSorting from "./ActivityCategoriesSorting";
import FAB from "../../components/FAB";
import { api } from "../../constants/Constants";
import WithAppContext from "../../context/WithAppContext";
import Paginator from "../../components/Paginator";

const MODULE_URL = "/admin/activity_categories";

class ActivityCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      page: 1,
      nextPage: null,
      prevPage: null,
      lastPage: null,
      filters: [],
      tree: [],
      currentItem: null,
      openItemsFormDialog: false,
      openSortDialog: false,
      parentId: this.props.id,
      categoryId: 0,
    };
  }

  setParentId = (parentId) => {
    this.setState({ parentId: this.props.id });
  };

  componentDidMount() {
    this.getAll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getAll();
    }
  }

  getAll = () => {
    this.props.changeLoading(true);
    const { page, filters, parentId } = this.state;
    let filters_string = JSON.stringify(filters);
    let url = "";
    if (filters && filters.length > 0) {
      if (parentId) {
        url = `${MODULE_URL}/${this.props.id}/sub_categories?page=${page}&filters=${filters_string}`;
      } else {
        url = `${MODULE_URL}?page=${page}&filters=${filters_string}`;
      }
    } else {
      if (parentId) {
        url = `${MODULE_URL}/${this.props.id}/sub_categories?page=${page}`;
      } else {
        url = `${MODULE_URL}?page=${page}`;
      }
    }
    api
      .get(url)
      .then((res) => {
        const response = res.data.data;
        this.setState(
          {
            items: response.data,
            nextPage: response.next_page_url,
            prevPage: response.prev_page_url,
            lastPage: response.last_page,
          },
          () => {
            this.props.changeLoading(false);
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleNext = () => {
    let page = this.state.page;
    page += 1;
    this.setState({ page: page }, () => {
      this.getAll();
    });
  };

  handlePrev = () => {
    let page = this.state.page;
    page -= 1;
    this.setState({ page: page }, () => {
      this.getAll();
    });
  };

  handleLastPage = () => {
    let page = this.state.lastPage;
    this.setState({ page: page }, () => {
      this.getAll();
    });
  };

  handleFirstPage = () => {
    this.setState({ page: 1 });
  };

  handleClear = () => {
    this.setState(
      {
        filters: "",
        page: 1,
        nextPage: null,
        prevPage: null,
        lastPage: null,
      },
      () => this.getAll()
    );
  };

  fabCallback = () => {
    if (!this.props.checkAuthorization("create", "ActivityCategories")) {
      this.props.showSnack(
        "You don't have permission to create activity categories",
        "error"
      );
    } else {
      this.setState({
        openItemsFormDialog: true,
        currentItem: null,
      });
    }
  };

  handleCloseItemsFormDialog = () => {
    this.setState({
      openItemsFormDialog: false,
      currentItem: null,
    });
  };

  handleOpenEditFrom = (id) => {
    if (!this.props.checkAuthorization("update", "ActivityCategories")) {
      this.props.showSnack(
        "You don't have permission to edit activity categories",
        "error"
      );
    } else {
      this.setState({
        currentItem: id,
        openItemsFormDialog: true,
      });
    }
  };

  handleDelete = (toBeDeleted) => {
    this.props.changeRefreshing(true);
    const data = {};
    let url = `${MODULE_URL}/`;
    const method = "POST";
    if (toBeDeleted instanceof Array) {
      url += "destroyMany";
      data.ids = toBeDeleted;
    } else {
      url += toBeDeleted.toString();
    }
    data._method = "DELETE";
    api({ method, url, data })
      .then((res) => {
        this.props.changeRefreshing(false);
        this.getAll();
        console.log(res);
      })
      .catch((err) => {
        this.props.changeRefreshing(false);
        console.log(err);
      });
  };

  handleFilter = (filters) => {
    this.setState({ filters }, () => {
      this.getAll();
    });
  };

  handleExport = (selected) => {
    this.props.changeLoading(true);
    const { filters, parentId } = this.state;
    filters.push({
      key: "parent_id",
      label: "ID",
      operator: "equals",
      operators: ["equals"],
      relation: null,
      subRelation: null,
      type: "text",
      value: parentId ? parentId : null,
    });
    let filters_string = JSON.stringify(filters);
    let data = JSON.stringify(selected);
    let url = "";
    if (selected.length > 0) {
      if (filters && filters.length > 0) {
        url = `${MODULE_URL}/export?selected=${data}&filters=${filters_string}`;
      } else {
        console.log(selected);
        url = `${MODULE_URL}/export?selected=${data}`;
      }
    } else {
      if (filters && filters.length > 0) {
        url = `${MODULE_URL}/export?&filters=${filters_string}`;
      } else {
        url = `${MODULE_URL}/export`;
      }
    }

    api
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "activity_categories.xlsx"); //or any other extension
        document.body.appendChild(link);
        this.props.changeLoading(false);
        link.click();
      })
      .catch((err) => {
        this.props.changeLoading(false);
        console.log(err);
      });
  };

  handleStatusChange = (id, field, value) => {
    this.props.changeLoading(true);
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append(field, value);
    api
      .post(`${MODULE_URL}/changeStatus/${id}`, formData)
      .then(() => {
        this.getAll();
      })
      .catch((err) => {
        console.log(err);
        this.props.changeLoading(false);
        if (err && err.response && err.response.data) {
          this.props.showSnack(err.response.data.message, "error");
        }
      });
  };

  handleOpenSorting = (source, id = 0) => {
    this.setState({ categoryId: id });
    const { parentId } = this.state;
    this.props.changeLoading(true);
    let uri = "";
    if (source == "categories")
      if (parentId) uri = `${MODULE_URL}/${this.props.id}/tree`;
      else uri = `${MODULE_URL}/tree`;
    else if (source == "items") uri = `${MODULE_URL}/${id}/items`;
    api
      .get(uri)
      .then((response) => {
        this.props.changeLoading(false);
        let tree = response.data.data;
        if (source == "items") {
          tree = response.data.data.activity_items;
          tree = tree.map((item) => {
            return { title: item.name, id: item.id };
          });
        }
        this.setState({
          openSortDialog: true,
          tree: tree,
        });
      })
      .catch((err) => {
        this.props.changeLoading(false);
        console.log(err);
      });
  };

  handleSortingSuccess = (tree) => {
    const { categoryId } = this.state;
    this.props.changeLoading(true);
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("tree", JSON.stringify(tree));
    let uri = "";
    if (!categoryId) uri = `${MODULE_URL}/tree`;
    else if (categoryId) uri = `${MODULE_URL}/${categoryId}/items`;
    api
      .post(uri, formData)
      .then((response) => {
        this.setState({
          openSortDialog: false,
          tree: [],
        });
        this.props.changeLoading(false);
        this.props.showSnack(response.data.message, "success");
      })
      .catch((err) => {
        console.log(err);
        this.props.changeLoading(false);
        this.setState({
          openSortDialog: false,
          tree: [],
        });
        if (err && err.response && err.response.data) {
          this.props.showSnack(err.response.data.message, "error");
        }
      });
  };

  render() {
    const { items, page, nextPage, prevPage, lastPage } = this.state;
    console.log(items.length);
    return (
      <Fragment>
        <ActivityCategoriesListing
          handleFilter={this.handleFilter}
          data={items}
          handleDelete={this.handleDelete}
          handleExport={this.handleExport}
          handleOpenEditFrom={this.handleOpenEditFrom}
          setParentId={this.setParentId}
          handleOpenSorting={this.handleOpenSorting}
          handleStatusChange={this.handleStatusChange}
        />
        {items && items.length > 0 && (
          <Paginator
            page={page}
            nextPage={nextPage}
            prevPage={prevPage}
            lastPage={lastPage}
            handlePrev={this.handlePrev}
            handleNext={this.handleNext}
            handleLastPage={this.handleLastPage}
            handleFirstPage={this.handleFirstPage}
          />
        )}
        <ActivityCategoriesFormDialog
          handleClose={this.handleCloseItemsFormDialog}
          handleSuccess={() => {
            this.setState({ openItemsFormDialog: false }, () => {
              this.getAll();
            });
          }}
          isOpen={this.state.openItemsFormDialog}
          id={this.state.currentItem}
        />
        <ActivityCategoriesSorting
          open={this.state.openSortDialog}
          handleClose={() => {
            this.setState({
              openSortDialog: false,
              tree: [],
            });
          }}
          handleSuccess={this.handleSortingSuccess}
          tree={this.state.tree}
        />
        <FAB callback={this.fabCallback} />
      </Fragment>
    );
  }
}

export default WithAppContext(ActivityCategories);
