import React, { useEffect, useRef, useState, useContext } from "react";
import AppContext from "../../context/AppContext";
import { api } from "../../constants/Constants";

import SelectInput from "../../components/InputFields/SelectInput";

export default function Content(props) {
  const [items, setItems] = useState([]);
  const [screens, setScreens] = useState([]);
  const context = useContext(AppContext);
  const { changeLoading } = context;
  const { setFieldValue, values } = props;
  useEffect(() => {
    getScreenOptions();
  }, []);
  useEffect(() => {
    if (values.screen_id) {
      getModulesContent(values.screen_id);
    }
  }, [values.screen_id]);

  const getScreenOptions = () => {
    api
      .get(`admin/screens/inOptions`)
      .then((res) => {
        setScreens(res.data.data);
        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const getModulesContent = (screen_id) => {
    api
      .get(`admin/screens/${screen_id}/contentOptions`)
      .then((res) => {
        const contents = res.data.data;
        setItems(res.data.data);
        if (contents.length <= 0) {
          setFieldValue("content_id", null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <SelectInput
        name="screen_id"
        label="Select internal screen"
        grid={6}
        setFieldValue={setFieldValue}
        options={screens}
      />
      <SelectInput
        name="details_id"
        label="Select content"
        grid={6}
        options={items}
        disabled={items.length <= 0}
        setFieldValue={setFieldValue}
      />
    </>
  );
}
