import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/InputFields/TextInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import SelectInput from "../../components/InputFields/SelectInput";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import Select from "react-select";
import { yupPassword } from "../../helpers/Validators";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 400,
    marginRight: "1vw",
    marginBottom: "1vw",
    marginTop: "2vw",
    border: "1px solid rgb(55, 17, 109, 0.5)",

    // This line disable the blue border
    boxShadow: "none",
    borderRadius: 4,
    "&:hover": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
      borderRadius: 4,
    },
  }),
};
// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  is_active: false,
  role_id: 0,
};

const MODULE_URL = "admins";

function AdminsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();
  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState("");

  // const {} = useFormikContext();

  useEffect(() => {
    if (isOpen && roles.length <= 0) {
      getRoles();
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let role = {
            label: res.data.data.role.name,
            value: res.data.data.role.id,
          };
          setItem(res.data.data);
          setRole(role);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
      setRole("");
    }
  }, [id, isOpen]);

  const getRoles = () => {
    api
      .get("admin/roles/rolesForAdmins")
      .then((response) => {
        let all_roles = response.data.data.data;
        all_roles = all_roles.map((role) => {
          return { label: role.name, value: role.id };
        });
        setRoles(all_roles);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = () => {
    changeRefreshing(true);
    const form = formRef.current;
    const { isValid, values, setSubmitting, setErrors, errors, validateForm } =
      form;
    validateForm();
    if (!isValid) {
      Object.keys(errors).map((key) => {
        showSnack(`${key}: ${errors[key]}`, "error");
      });
      console.log(errors);
      return;
    }
    setSubmitting(true);
    let method = "POST";
    let url = `${MODULE_URL}`;
    if (id) {
      values._method = "PUT";
      url += "/" + id;
    }
    if (!values.password || values.password == "") {
      delete values["password"];
    }
    if (values.role) {
      delete values["role"];
    }
    values.is_active = values.is_active ? 1 : 0;
    values.role_id = role.value;
    console.log(values);
    const data = transformObjectToFromData(values);
    api({ method, url, data })
      .then((res) => {
        changeRefreshing(false);
        setSubmitting(false);
        handleSuccess();
      })
      .catch((err) => {
        changeRefreshing(false);
        setSubmitting(false);
        console.log("error", err);
        if (err.response) {
          setErrors(err.response.data);
        }
      });
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle2"
              className={classes.flex}
              style={{
                color: headerTextColor,
              }}
            >
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              style={{
                color: headerTextColor,
              }}
              disabled={isDisabled()}
              onClick={handleSubmit}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              first_name: Yup.string().max(15, "Must be 15 characters or less"),
              // .required("Required")
              last_name: Yup.string()
                .max(15, "Must be 15 characters or less")
                .required("Required"),
              email: Yup.string()
                .email("Invalid email address`")
                .required("Required"),
              password: Yup.string().matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
              ),
              password_confirmation: Yup.string().oneOf(
                [Yup.ref("password"), null],
                "Passwords must match"
              ),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              await new Promise((r) => setTimeout(r, 500));
              setSubmitting(false);
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TextInput
                      name="email"
                      label="Please enter your email"
                      grid={12}
                    />
                    <TextInput
                      name="first_name"
                      label="Please enter your first name"
                      grid={6}
                    />
                    <TextInput
                      name="last_name"
                      label="Please enter your last name"
                      grid={6}
                    />
                    <TextInput
                      name="password"
                      label="Please enter your password"
                      grid={6}
                      type="password"
                    />
                    <TextInput
                      name="password_confirmation"
                      label="Confirm your password"
                      grid={6}
                      type="password"
                    />
                    {/* <SelectInput
                      name="role_id"
                      label="Select an admin role"
                      placeholder="Select an admin role"
                      grid={12}
                      options={roles}
                    /> */}
                    <Select
                      name="role_id"
                      label="Select an admin role"
                      placeholder="Select an admin role"
                      id="color"
                      styles={customStyles}
                      closeMenuOnSelect={true}
                      options={roles}
                      value={role}
                      onChange={setRole}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default AdminsFormDialog;
