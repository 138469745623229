import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  root3: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
});
class SubscriptionsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleStateChange = (event) => {
    const value = event.target.value,
      name = event.target.name;
    this.setState({ [name]: value });
  };

  handleFilter = () => {
    let filters = {};
    const {} = this.state;
    this.props.handleFilter(filters);
  };

  handleClear = () => {
    this.setState({}, () => this.props.handleFilter({}));
  };

  render() {
    const { classes } = this.props;
    const style = {
      margin: "30px 15px",
      color: "#fff",
    };
    const {} = this.state;
    let disabled = false;
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<FilterListIcon color="primary" />}>
          <Typography className={classes.heading}>Filters</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Fragment>
            <Grid container spacing={24}>
              <Button
                variant="contained"
                color="primary"
                style={style}
                disabled={disabled}
                onClick={() => this.handleFilter()}
              >
                Filter
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={style}
                disabled={disabled}
                onClick={() => this.handleClear()}
              >
                Clear
              </Button>
            </Grid>
          </Fragment>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
export default withStyles(styles)(SubscriptionsFilter);
