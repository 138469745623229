import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import DateTimeInput from "../../components/InputFields/DateTimeInput";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty = {
  due_date: null,
};

const MODULE_URL = "admin/subscriptions";
const Extend_package_URL = "admin/subscriptions/extend-package";

function SubscriptionsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          setItem(res.data.data);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  return (
    <div>
      <Dialog
        fullWidth="50%"
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <DialogContentText>Extend the package</DialogContentText>
          <div className={classes.root}>
            <Formik
              enableReinitialize={true}
              innerRef={formRef}
              initialValues={item}
              validationSchema={Yup.object({
                due_date: Yup.date().required("Required"),
              })}
              onSubmit={async (
                values,
                { setSubmitting, setErrors, setFieldError }
              ) => {
                await new Promise((r) => setTimeout(r, 500));
                changeRefreshing(true);
                setSubmitting(true);
                let method = "POST";
                let url = `${Extend_package_URL}`;
                const input = { due_date: values.due_date };
                if (id) {
                  input._method = "PUT";
                  url += "/" + id;
                }
                const data = transformObjectToFromData(input);
                api({ method, url, data })
                  .then((res) => {
                    changeRefreshing(false);
                    setSubmitting(false);
                    handleSuccess();
                    showSnack(res.data.message, "success");
                  })
                  .catch((err) => {
                    changeRefreshing(false);
                    setSubmitting(false);
                    console.log("error", err);
                    if (err.response) {
                      setErrors(err.response.data);
                    }
                  });
              }}
            >
              {(props) => {
                const { errors, setFieldError, setFieldValue } = props;
                return (
                  <form>
                    <Grid container spacing={3}>
                      <DateTimeInput
                        name="due_date"
                        label="Due Date:"
                        grid={6}
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        errors={errors}
                      />
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={() => handleSubmit()}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubscriptionsFormDialog;
