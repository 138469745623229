import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import Select from "react-select";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import {
  yupPhone,
  yupRequiredTranslatableString,
} from "../../helpers/Validators";
import SelectInput from "../../components/InputFields/SelectInput";
import MultiSelectInput from "../../components/InputFields/MultiSelectInput";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    marginRight: "1vw",
    marginBottom: "1vw",
    marginTop: "2vw",
    border: "1px solid rgb(55, 17, 109, 0.5)",

    // This line disable the blue border
    boxShadow: "none",
    borderRadius: 4,
    "&:hover": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
      borderRadius: 4,
    },
  }),
};

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const slugValidation = /^[A-Z0-9][0-9A-Z\_]+[A-Z0-9]$/;

const empty = {
  title: "",
  slug: "",
  building: "",
  city_id: "",
  floor: "",
  apartment: "",
  street: "",
  email: "",
  phone: "",
  note: "",
  is_active: 1,
};

const MODULE_URL = "admin/locations";

function LocationsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const [coaches, setCoaches] = useState([]);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [cities, setCities] = useState([]);
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getCities();
      getCoaches();
      getProviders();
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          res.data.data.coaches = res.data.data.coaches.map(
            (coach) => coach.id
          );
          res.data.data.payment_providers = res.data.data.payment_providers.map(
            (provider) => provider.id
          );
          setItem(res.data.data);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getProviders = () => {
    api
      .get(`admin/payment-providers?limit=100`)
      .then((res) => {
        setProviders(res.data.data.data);
      })
      .catch((err) => {
        changeLoading(false);
      });
  };

  const getCoaches = () => {
    api
      .get(`admin/coaches?limit=100`)
      .then((res) => {
        setCoaches(res.data.data.data);
        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const getCities = () => {
    api
      .get(`${MODULE_URL}/cities`)
      .then((res) => {
        setCities(res.data.data);
        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              title: yupRequiredTranslatableString(),
              slug: Yup.string().required("Required field").matches(slugValidation, "Invalid slug. the slug should contain only numbers, uppercase letters and underscores"),
              // note: yupRequiredTranslatableString(null, false),
              building: Yup.string().min(1).max(10).required("Required field"),
              floor: Yup.string().min(1).max(10).required("Required field"),
              street: yupRequiredTranslatableString(),
              apartment: Yup.number().required("Required field"),
              email: Yup.string().email().nullable(),
              phone: yupPhone().nullable(),
              city_id: Yup.number()
                .required("Required field")
                .min(1, "Required field"),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                    setErrors(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, values, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="title"
                      label="Please enter location title"
                      grid={6}
                    />
                    <SelectInput
                      name="city_id"
                      label="Select City"
                      grid={3}
                      valueKey={"id"}
                      labelKey={"name"}
                      options={cities}
                      setFieldValue={setFieldValue}
                    />

                    <TextInput
                      name="building"
                      label="Please enter building"
                      grid={3}
                    />

                    <TextInput
                      name="floor"
                      label="Please enter floor"
                      grid={3}
                    />

                    <TextInput
                      name="apartment"
                      label="Please enter apartment"
                      grid={3}
                    />
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="street"
                      label="Please enter street"
                      grid={6}
                    />
                    <MultiSelectInput
                      name="payment_providers"
                      label="Select payment providers"
                      grid={6}
                      valueKey="id"
                      labelKey="name"
                      options={providers}
                      value={item.payment_providers}
                      closeMenuOnSelect={false}
                      setFieldValue={setFieldValue}
                    />
                    <MultiSelectInput
                      name="coaches"
                      label="Select filtered coaches"
                      grid={6}
                      valueKey="id"
                      labelKey="name"
                      options={coaches}
                      value={item.coaches}
                      closeMenuOnSelect={false}
                      setFieldValue={setFieldValue}
                    />

                    <TextInput
                      name="email"
                      label="Please enter email"
                      grid={6}
                    />
                    <TextInput
                      name="phone"
                      label="Please enter phone"
                      grid={6}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <TranslatableTextAreaInput
                      translations={item.allTranslations}
                      name="note"
                      label="Note"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />

                    <TextInput
                      name="slug"
                      label="Please enter slug"
                      grid={3}
                      setFieldValue={setFieldValue}
                    />

                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default LocationsFormDialog;
