import Admins from "../pages/admins/Admins";
import AdminRoles from "../pages/adminRoles/Roles";
import UserRoles from "../pages/userRoles/Roles";
import Events from "../pages/events/Events";
import PhoneDirectoryCategories from "../pages/phone_directory_categories/PhoneDirectoryCategories";
import PhoneDirectoryItems from "../pages/phone_directory_items/PhoneDirectoryItems";
import ActivityCategories from "../pages/activity_categories/ActivityCategories";
import ActivityItems from "../pages/activity_items/ActivityItems";

/* PLOP_INJECT_IMPORT */
import PromoCodes from "../pages/PromoCodes/PromoCodes";
import Subscriptions from "../pages/Subscriptions/Subscriptions";
import FormSubmissions from "../pages/FormSubmissions/FormSubmissions";
import PrivateTrainingRequests from "../pages/PrivateTrainingRequests/PrivateTrainingRequests";
import ClassLocations from "../pages/ClassLocations/ClassLocations";
import Schedules from "../pages/Schedules/Schedules";
import Classes from "../pages/Classes/Classes";
import Packages from "../pages/Packages/Packages";
import Locations from "../pages/Locations/Locations";
import Coaches from "../pages/Coaches/Coaches";
import ProjectTypes from "../pages/ProjectTypes/ProjectTypes";
import PropertyTypes from "../pages/PropertyTypes/PropertyTypes";
import RealEstateSubmissions from "../pages/RealEstateSubmissions/RealEstateSubmissions";
import Facilities from "../pages/Facilities/Facilities";
import AppSettings from "../pages/AppSettings/AppSettings";
import ImportedUsers from "../pages/ImportedUsers/ImportedUsers";
import Rentals from "../pages/Rentals/Rentals";
import Relations from "../pages/Relations/Relations";
import Menus from "../pages/Menus/Menus";
import Screens from "../pages/Screens/Screens";
import Users from "../pages/Users/Users";
import ContactUs from "../pages/ContactUs/ContactUs";
import Departments from "../pages/Departments/Departments";
import ServiceRequestRates from "../pages/ServiceRequestRates/ServiceRequestRates";
import ServicesRequests from "../pages/ServicesRequests/ServicesRequests";
import Settings from "../pages/Settings/Settings";
import Notifications from "../pages/Notifications/Notifications";
import TimeRanges from "../pages/TimeRanges/TimeRanges";
import ServicesItems from "../pages/ServicesItems/ServicesItems";
import Servicescategories from "../pages/Servicescategories/Servicescategories";
import Projects from "../pages/Projects/Projects";
import Guidelines from "../pages/Guidelines/Guidelines";
import ProjectConstructionUpdateCategories from "../pages/ProjectConstructionUpdateCategories/ProjectConstructionUpdateCategories";
import Dashboard from "../pages/Dashboard/Dashboard";
import GuestInvitations from "../pages/GuestInvitations/GuestInvitations";
import Content from "../pages/content/Contents";
import HomeWidgets from "../pages/HomeWidgets/HomeWidget";
import HomeBanners from "../pages/HomeBanners/HomeBanners";
import Forms from "../pages/Forms/Forms";
import FormFields from "../pages/FormFields/FormFields";

const routes = [
  {
    path: "/",
    component: () => <Dashboard />,
    permission: "view",
    exact: "exact",
    module: null,
  },
  {
    path: "/admins",
    component: () => <Admins />,
    permission: "view",
    exact: "exact",
    module: "Admins",
  },
  {
    path: "/roles/users",
    component: () => <UserRoles />,
    permission: "view",
    exact: "exact",
    module: "Roles",
  },
  {
    path: "/roles/admins",
    component: () => <AdminRoles />,
    permission: "view",
    exact: "exact",
    module: "Roles",
  },
  {
    path: "/events",
    component: () => <Events />,
    permission: "view",
    exact: "exact",
    module: "Events",
  },
  {
    path: "/phone_directory_categories",
    component: () => <PhoneDirectoryCategories />,
    permission: "view",
    exact: "exact",
    module: "PhoneDirectoryCategories",
  },
  {
    path: "/phone_directory_categories/:id/phone_directories_items",
    component: (props) => <PhoneDirectoryItems id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "PhoneDirectoryItems",
  },
  {
    path: "/activity_categories",
    component: () => <ActivityCategories />,
    permission: "view",
    exact: "exact",
    module: "ActivityCategories",
  },
  {
    path: "/activity_categories/:id/sub_categories",
    component: (props) => <ActivityCategories id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "ActivityCategories",
  },
  {
    path: "/activity_categories/:id/activity_items",
    component: (props) => <ActivityItems id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "ActivityItems",
  },
  {
    path: "/activity_items",
    component: () => <ActivityItems />,
    permission: "view",
    exact: "exact",
    module: "ActivityItems",
  },
  {
    path: "/appSettings",
    component: (props) => <AppSettings />,
    permission: "view",
    exact: "exact",
    module: "AppSettings",
  },
  {
    path: "/facilities",
    component: (props) => <Facilities />,
    permission: "view",
    exact: "exact",
    module: "Facilities",
  },
  {
    path: "/imported_users",
    component: (props) => <ImportedUsers />,
    permission: "view",
    exact: "exact",
    module: "ImportedUsers",
  },
  {
    path: "/rentals",
    component: (props) => <Rentals />,
    permission: "view",
    exact: "exact",
    module: "Rentals",
  },
  {
    path: "/relations",
    component: (props) => <Relations />,
    permission: "view",
    exact: "exact",
    module: "Relations",
  },
  {
    path: "/menus",
    component: (props) => <Menus />,
    permission: "view",
    exact: "exact",
    module: "Menus",
  },
  {
    path: "/screens",
    component: (props) => <Screens />,
    permission: "view",
    exact: "exact",
    module: "Screens",
  },
  {
    path: "/users",
    component: (props) => <Users />,
    permission: "view",
    exact: "exact",
    module: "Users",
  },
  {
    path: "/service_request_rates",
    component: (props) => <ServiceRequestRates />,
    permission: "view",
    exact: "exact",
    module: "ServicesRequests",
  },
  {
    path: "/services_requests",
    component: (props) => <ServicesRequests />,
    permission: "view",
    exact: "exact",
    module: "ServicesRequests",
  },

  {
    path: "/settings",
    component: (props) => <Settings />,
    permission: "view",
    exact: "exact",
    module: "Settings",
  },
  {
    path: "/guidelines",
    component: (props) => <Guidelines />,
    permission: "view",
    exact: "exact",
    module: "Guidelines",
  },
  {
    path: "/contact_us",
    // permission: "view",
    exact: "exact",
    component: (props) => <ContactUs />,
    permission: "Contact_us",
    module: "Contact_us",
  },
  {
    path: "/departments",
    // permission: "view",
    exact: "exact",
    component: (props) => <Departments />,
    permission: "Departments",
  },

  {
    path: "/notifications",
    component: (props) => <Notifications />,
    permission: "view",
    exact: "exact",
    module: "notifications",
  },
  {
    path: "/services_categories/:id/services_items",
    component: (props) => <ServicesItems id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "ServicesItems",
  },
  {
    path: "/services_categories/:id/sub_categories",
    component: (props) => <Servicescategories id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "ServicesCategories",
  },
  {
    path: "/services_categories",
    component: (props) => <Servicescategories />,
    permission: "view",
    exact: "exact",
    module: "ServicesCategories",
  },
  {
    path: "/time_ranges",
    component: (props) => <TimeRanges />,
    permission: "view",
    exact: "exact",
    module: "TimeRanges",
  },
  {
    path: "/projects",
    component: (props) => <Projects />,
    permission: "view",
    exact: "exact",
    module: "Projects",
  },
  {
    path: "/projects/:id/projectTypes",
    component: (props) => <ProjectTypes id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "Projects",
  },
  {
    path: "/propertyTypes",
    component: (props) => <PropertyTypes />,
    permission: "view",
    exact: "exact",
    module: "PropertyTypes",
  },
  {
    path: "/realEstatesubmissions",
    component: (props) => <RealEstateSubmissions />,
    permission: "view",
    exact: "exact",
    module: "RealEstateSubmissions",
  },
  {
    path: "/projects/:id/constructionUpdateCategory/",
    component: (props) => (
      <ProjectConstructionUpdateCategories id={props.match.params.id} />
    ),
    permission: "view",
    exact: "exact",
    module: "Projects",
  },
  {
    path: "/guest_invitations",
    component: (props) => <GuestInvitations />,
    permission: "view",
    exact: "exact",
    module: "GuestInvitations",
  },
  {
    path: "/content",
    component: (props) => <Content />,
    permission: "view",
    exact: "exact",
    module: "AppSettings",
  },
  {
    path: "/home_widget",
    component: (props) => <HomeWidgets />,
    permission: "view",
    exact: "exact",
    module: "AppSettings",
  },
  /* PLOP_INJECT_ROUTE_IMPORT */
  {
    path: "/promo-codes",
    component: (props) => <PromoCodes />,
    permission: "view",
    exact: "exact",
    module: "PromoCodes",
  },
  {
    path: "/subscriptions",
    component: (props) => <Subscriptions />,
    permission: "view",
    exact: "exact",
    module: "Subscriptions",
  },
  {
    path: "/form_submissions/:type",
    component: (props) => <FormSubmissions type={props.match.params.type} />,
    permission: "view",
    exact: "exact",
    module: "FormSubmissions",
  },
  {
    path: "/classes/:id/locations",
    component: (props) => <ClassLocations {...props} />,
    permission: "view",
    exact: "exact",
    module: "ClassLocations",
  },
  {
    path: "/classes/:id/locations/:location/schedules",
    component: (props) => <Schedules {...props} related="classes" />,
    permission: "view",
    exact: "exact",
    module: "Schedules",
  },
  {
    path: "/classes",
    component: (props) => <Classes {...props} />,
    permission: "view",
    exact: "exact",
    module: "Classes",
  },
  {
    path: "/private-training-requests",
    component: (props) => <PrivateTrainingRequests {...props} />,
    permission: "view",
    exact: "exact",
    module: "PrivateTrainingRequests",
  },
  {
    path: "/private/:id/locations/:location/schedules",
    component: (props) => <Schedules {...props} related="private" />,
    permission: "view",
    exact: "exact",
    module: "Schedules",
  },
  {
    path: "/packages",
    component: (props) => <Packages />,
    permission: "view",
    exact: "exact",
    module: "Packages",
  },
  {
    path: "/locations",
    component: (props) => <Locations />,
    permission: "view",
    exact: "exact",
    module: "Locations",
  },
  {
    path: "/coaches",
    component: (props) => <Coaches />,
    permission: "view",
    exact: "exact",
    module: "Coaches",
  },
  {
    path: "/home-banners",
    component: (props) => <HomeBanners />,
    exact: true,
    permission: "view",
    module: "HomeBanners",
  },
  {
    path: "/forms",
    component: (props) => <Forms />,
    permission: "view",
    exact: "exact",
    module: "Forms",
  },
  {
    path: "/forms/:id/form_fields",
    component: (props) => <FormFields id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "FormFields",
  },
];

export default routes;
