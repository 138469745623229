import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import SelectInput from "../../components/InputFields/SelectInput";
import { Switch } from "@material-ui/core";
import TimeInput from "../../components/InputFields/TimeInput";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  name: "",
};

const weekDays = [
  {
    label: "Saturday",
    value: "sat",
  },
  {
    label: "Sunday",
    value: "sun",
  },
  {
    label: "Monday",
    value: "mon",
  },
  {
    label: "Tuesday",
    value: "tue",
  },
  {
    label: "Wednesday",
    value: "wed",
  },
  {
    label: "Thursday",
    value: "thu",
  },
  {
    label: "Friday",
    value: "fri",
  },
];

function SchedulesFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { isOpen, handleSuccess, locationId } = props;
  const MODULE_URL = `/admin/${props.match.params.id}/schedules?related=${props.related}&location=${locationId}`;

  useEffect(() => {
    if (isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}`)
        .then((res) => {
          setItem({ schedules: res.data.data.data });
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    }
  }, [isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              Save
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            // validationSchema={Yup.object({
            //   name: Yup.string()
            //     .max(256, "Must be 256 characters or less")
            //     .required("Required"),
            // })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };

              input.schedules = JSON.stringify(input.schedules);
              if (props.match.params.location)
                input.location = props.match.params.location;
              const data = transformObjectToFromData(input);
              url = `${url}?related=${props.related}`;
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                    setErrors(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, values } = props;
              console.log("values...", values);
              return (
                <form>
                  <Grid container spacing={3}>
                    <Grid container spacing={3}>
                      <FieldArray
                        name="schedules"
                        render={(arrayHelpers) => (
                          <>
                            {values.schedules &&
                              values.schedules.length > 0 &&
                              values.schedules.map((item, index) => (
                                <React.Fragment key={index}>
                                  <SelectInput
                                    name={`schedules.${index}.day`}
                                    label="Select day"
                                    grid={4}
                                    options={weekDays}
                                    setFieldValue={setFieldValue}
                                  />
                                  <TimeInput
                                    name={`schedules.${index}.hour`}
                                    label="Hour:"
                                    grid={4}
                                    setFieldValue={setFieldValue}
                                    setFieldError={setFieldError}
                                    errors={errors}
                                    format="HH:mm"
                                  />
                                  <Grid item xs={4}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                        // setPackagesCount(packagesCount - 1);
                                        // if (index > -1) {
                                        //   isCustomPrices.splice(index, 1);
                                        // }
                                        // setIsCustomPrices(isCustomPrices);
                                      }}
                                    >
                                      Remove schedule
                                    </Button>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                arrayHelpers.push({
                                  day: "",
                                  hour: "",
                                });
                                // isCustomPrices[packagesCount] = 0;
                                // setIsCustomPrices(isCustomPrices)
                                // setPackagesCount(packagesCount + 1);
                              }}
                            >
                              Add schedule
                            </Button>
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default SchedulesFormDialog;
