import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import MultiSelectInput from "../../components/InputFields/MultiSelectInput";
import {
  yupPhone,
  yupRequiredTranslatableString,
} from "../../helpers/Validators";
import SelectInput from "../../components/InputFields/SelectInput";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  name: "",
  is_active: 1,
  is_group: 0,
  cover_image: empty_image,
  gallery_image: [],
  form: "",
};

const MODULE_URL = "admin/classes";

function ClassesFormDialog(props) {
  const classesStyles = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const [classes, setClasses] = useState([]);
  const [locations, setLocations] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [forms, setForms] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getForms();
      getCoaches();
      getLocations();
      getClasses();
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let data = res.data.data;
          data.coaches = data.coaches.map((coach) => coach.id);
          data.locations = data.locations.map((location) => location.id);
          data.group = data?.group?.id;

          if (!data.group) {
            delete data.group;
          }

          // delete data.coaches;
          delete data.schedules;
          delete data.packages;
          if (data && data.form) {
            data.form = data.form.id;
          }
          console.log({ data });
          setItem(data);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getCoaches = () => {
    api
      .get(`admin/coaches?limit=100`)
      .then((res) => {
        setCoaches(res.data.data.data);
        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };
  const getClasses = () => {
    api
      .get(`admin/classes/groups?limit=100`)
      .then((res) => {
        setClasses(res.data.data);
        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const getLocations = () => {
    api
      .get(`admin/locations?limit=100`)
      .then((res) => {
        setLocations(res.data.data.data);
        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const getForms = () => {
    api
      .get("admin/forms/all_list")

      .then((res) => {
        let forms = res.data.data;
        forms = forms.map((form) => {
          return { label: form.name, value: form.id };
        });
        setForms(forms);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    console.log(values);
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classesStyles.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classesStyles.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classesStyles.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              title: yupRequiredTranslatableString(),
              // note: yupRequiredTranslatableString(null, false),
              coaches: Yup.array()
                .of(Yup.number().min(1))
                .min(1, "Required field")
                .required("Required field"),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  console.log("res", res);
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                    setErrors(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, values } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="title"
                      label="Please enter Class title"
                      grid={6}
                    />
                    <MultiSelectInput
                      name="coaches"
                      label="Select filtered coaches"
                      grid={6}
                      valueKey="id"
                      labelKey="name"
                      options={coaches}
                      value={item.coaches}
                      closeMenuOnSelect={false}
                      setFieldValue={setFieldValue}
                    />
                    {values.is_group == 0 && (
                      <SelectInput
                        name="group"
                        label="Select Group Class"
                        grid={6}
                        valueKey="id"
                        labelKey="title"
                        options={classes}
                      />
                    )}
                    {values.group == null && (
                      <SelectInput
                        name="form"
                        label="Select Form"
                        grid={6}
                        options={forms}
                      />
                    )}
                    {values.group != null && (
                      <MultiSelectInput
                        name="locations"
                        label="Select filtered locations"
                        grid={6}
                        valueKey="id"
                        labelKey="title"
                        options={locations}
                        value={item.locations}
                        closeMenuOnSelect={false}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <TextInput
                      name="duration"
                      label="Please enter class duration per minutes"
                      grid={6}
                    />
                    <TranslatableTextAreaInput
                      translations={item.allTranslations}
                      name="description"
                      label="Description"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <SwitchInput
                      name="is_group"
                      label="Is Group"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <ImageInput
                      name="cover_image"
                      label="Cover Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <GalleryInput
                      name="gallery"
                      label="Gallery Images"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ClassesFormDialog;
