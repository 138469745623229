import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";
import Select from "react-select";
import TextInput from "../../components/InputFields/TextInput";
import SelectInput from "../../components/InputFields/SelectInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateInput from "../../components/InputFields/DateInput";
import ImageInput from "../../components/InputFields/ImageInput";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 400,
    marginRight: "1vw",
    marginBottom: "1vw",
    marginTop: "2vw",
    border: "1px solid rgb(55, 17, 109, 0.5)",

    // This line disable the blue border
    boxShadow: "none",
    borderRadius: 4,
    "&:hover": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
      borderRadius: 4,
    },
  }),
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  phone: "",
  first_name: "",
  last_name: "",
  email: "",
  is_active: 1,
  is_email_verified: 0,
  gender: 0,
  birth_date: "",
};

const MODULE_URL = "admin/users";

function UsersFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [gender, setGender] = useState("");

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          setItem(res.data.data);
          let gender = "";
          if (res.data.data.gender == 0) {
            gender = { label: "Not specified", value: 0 };
          } else if (res.data.data.gender == 1) {
            gender = { label: "Male", value: 1 };
          } else if (res.data.data.gender == 2) {
            gender = { label: "Female", value: 2 };
          }
          setGender(gender);

          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
      setGender("");
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              phone: Yup.string()
                .max(256, "Must be 256 characters or less")
                .required("Required"),
            })}
            onSubmit={async (values, {setSubmitting, setErrors, setFieldError}) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values, gender: gender.value };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                  setErrors(err.response.data);
                }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TextInput
                      name="phone"
                      label="Please enter user phone number with country code"
                      grid={6}
                    />
                    {/* <SelectInput
                      name="gender"
                      label="Select user gender"
                      grid={6}
                      options={[
                        { label: "Not specified", value: 0 },
                        { label: "Male", value: 1 },
                        { label: "Female", value: 2 },
                      ]}
                    /> */}
                    <Select
                      placeholder="Select user gender"
                      label="Select user gender"
                      id="color"
                      name="gender"
                      styles={customStyles}
                      closeMenuOnSelect={true}
                      options={[
                        { label: "Not specified", value: 0 },
                        { label: "Male", value: 1 },
                        { label: "Female", value: 2 },
                      ]}
                      value={gender}
                      onChange={setGender}
                    />
                    <TextInput
                      name="first_name"
                      label="Please enter user first name"
                      grid={6}
                    />
                    <TextInput
                      name="last_name"
                      label="Please enter user last name"
                      grid={6}
                    />
                    <TextInput
                      name="email"
                      label="Please enter user email"
                      grid={6}
                    />
                    <SwitchInput
                      name="is_email_verified"
                      label="Is email verified"
                      grid={6}
                      setFieldValue={setFieldValue}
                    />
                    <DateInput
                      name="birth_date"
                      label="Birth date"
                      grid={6}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={6}
                      setFieldValue={setFieldValue}
                    />
                    <ImageInput
                      name="profile"
                      label="Profile"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default UsersFormDialog;
