import React, { Component } from "react";
import { isLoggedIn } from "../../auth/Auth";
import { API_URL } from "../../constants/Constants";
import WithAppContext from "../../context/WithAppContext";
import PropTypes from "prop-types";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import CircularProgress from "@material-ui/core/CircularProgress";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#3D4144",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#F39870",
      contrastText: "#000",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles = (theme) => ({
  card: {
    maxWidth: 436,
    margin: "auto",
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  container: {
    textAlign: "center",
    alignContent: "center",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    display: "flex",
    height: "100vh",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  btn: {
    width: "100%",
  },
  container2: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  progress: {
    position: "relative",
    margin: "auto",
    display: "block",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  menu: {
    width: 200,
  },
  margin: {
    width: "100%",
    margin: theme.spacing(1),
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      submitting: false,
      snackbarMessage: "",
      snackbarOpen: false,
      emailError: false,
      passwordError: false,
    };
  }

  componentDidMount() {
    console.log(this.props);
    // if (isLoggedIn()) {
    //   window.location.href = "/";
    // } else {
    // window.history.pushState({ login: "login" }, "login page", "login");
    // }
  }

  handleRequestClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleEmailChange = (event) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = false;
    if (!re.test(event.target.value)) {
      error = true;
    }
    this.setState({ email: event.target.value, emailError: error });
  };

  handlePasswordChange = (event) => {
    let error = false;
    if (event.target.value === "") error = true;
    this.setState({ password: event.target.value, passwordError: error });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.emailError || this.state.passwordError) {
      this.setState({
        snackbarMessage: "Please Enter Valid Data",
        snackbarOpen: true,
      });
    } else {
      this.setState({ submitting: true });
      var loginForm = document.getElementById("loginForm");
      axios({
        method: "post",
        url: API_URL + "admins/auth/login",
        mode: "cors",
        data: new FormData(loginForm),
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            this.setState({
              submitting: false,
              snackbarOpen: true,
              snackbarMessage: response.statusText,
            });
          }
        })
        .then((responseData) => {
          if (
            responseData.data.access_token !== undefined ||
            responseData.data.access_token !== ""
          ) {
            this.props.login(responseData.data.access_token);
            window.location.href = "/";
          } else {
            this.setState({
              submitting: false,
              snackbarOpen: true,
              snackbarMessage: "Something went wrong. Try again later",
            });
          }
        })
        .catch((error) =>
          this.setState(
            {
              submitting: false,
              snackbarOpen: true,
              snackbarMessage: "Something went wrong. Try again later",
            },
            () => {
              console.log(error);
            }
          )
        );
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  checkEmpty = () => {
    if (
      this.state.email === "" ||
      this.state.password === "" ||
      this.state.emailError ||
      this.state.passwordError
    )
      return true;
    else return false;
  };

  _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="error"
            message={this.state.snackbarMessage}
          />
        </Snackbar>
        <div className={classes.container}>
          <Card className={classes.card} onKeyPress={this._handleKeyPress}>
            <CardMedia
              className={classes.media}
              image="/milango.png"
              title="Milango Admin Logo"
            />
            {this.state.submitting && (
              <CircularProgress className={classes.progress} />
            )}
            <CardContent>
              <form
                id="loginForm"
                className={classes.container2}
                autoComplete="off"
              >
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  className={classes.textField}
                  type="mail"
                  value={this.state.email}
                  fullWidth
                  onChange={(event) => this.handleEmailChange(event)}
                  margin="normal"
                  error={this.state.emailError}
                />
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  className={classes.textField}
                  type="password"
                  fullWidth
                  onChange={(event) => this.handlePasswordChange(event)}
                  autoComplete="current-password"
                  margin="normal"
                  error={this.state.passwordError}
                />
              </form>
            </CardContent>
            <CardActions>
              <Button
                disabled={this.checkEmpty() ? true : false}
                onClick={(event) => this.handleSubmit(event)}
                className={classes.btn}
                color="primary"
              >
                Login
              </Button>
            </CardActions>
          </Card>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default WithAppContext(withStyles(styles)(Login));
