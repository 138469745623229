import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";

import TextInput from "../../components/InputFields/TextInput";
import SelectInput from "../../components/InputFields/SelectInput";
import { Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  location_id: "",
};

function ClassLocationsFormDialog(props) {
  const MODULE_URL = `/admin/classes/${props.match.params.id}/locations`;

  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const [locations, setLocations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [isCustomPrices, setIsCustomPrices] = useState([]);
  const [packagesCount, setPackagesCount] = useState(0);
  const [packagesUpdateStatus, setPackagesUpdateStatus] = useState(false);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;

  useEffect(() => {
    if (isOpen) {
      getPackages();
      getLocations();
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          res.data.data.location_id = id;
          setIsCustomPrices(
            res.data.data.packages.map((item) => item.is_custom_price)
          );
          setItem(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setItem(empty);
    }

    changeLoading(false);
  }, [id, isOpen]);

  const getPackages = () => {
    api
      .get(`admin/packages?limit=100`)
      .then((res) => {
        setPackages(res.data.data.data);
      })
      .catch((err) => {
        changeLoading(false);
      });
  };

  const getLocations = () => {
    api
      .get(`admin/locations?limit=100`)
      .then((res) => {
        setLocations(res.data.data.data);
      })
      .catch((err) => {
        changeLoading(false);
      });
  };

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button disabled={isDisabled()} onClick={handleSubmit}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              // name: Yup.string()
              //   .max(256, "Must be 256 characters or less")
              //   .required("Required"),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }

              input.packages = JSON.stringify(input.packages);
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                    setErrors(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, values } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <SelectInput
                      name="location_id"
                      label="Select location"
                      grid={6}
                      valueKey={"id"}
                      labelKey={"title"}
                      options={locations}
                      setFieldValue={setFieldValue}
                    />
                    <Grid container spacing={3}>
                      <FieldArray
                        name="packages"
                        render={(arrayHelpers) => (
                          <>
                            {values.packages &&
                              values.packages.length > 0 &&
                              values.packages.map((item, index) => {
                                // item.package_id = item.id;
                                return (
                                  <React.Fragment key={index}>
                                    <SelectInput
                                      name={`packages.${index}.package_id`}
                                      label="Select package"
                                      grid={4}
                                      valueKey={"id"}
                                      labelKey={["title", "price"]}
                                      options={packages}
                                      setFieldValue={setFieldValue}
                                    />
                                    <TextInput
                                      name={`packages.${index}.price`}
                                      label="Price"
                                      disabled={!isCustomPrices[index]}
                                      grid={4}
                                    />
                                    <Grid item xs={4}>
                                      <label htmlFor="is_custom_price">
                                        Custom Price:
                                        <Switch
                                          name={`packages.${index}.is_custom_price`}
                                          key={`packages.${index}.is_custom_price`}
                                          checked={isCustomPrices[index]}
                                          onChange={() => {
                                            isCustomPrices[index] =
                                              isCustomPrices[index] ? 0 : 1;
                                            setIsCustomPrices(isCustomPrices);
                                            if (!isCustomPrices[index]) {
                                              setFieldValue(
                                                `package.${index}.price`,
                                                ""
                                              );
                                            }
                                            setPackagesUpdateStatus(
                                              !packagesUpdateStatus
                                            );
                                          }}
                                          color="secondary"
                                        />
                                      </label>

                                      <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          setPackagesCount(packagesCount - 1);
                                          if (index > -1) {
                                            isCustomPrices.splice(index, 1);
                                          }
                                          setIsCustomPrices(isCustomPrices);
                                        }}
                                      >
                                        Remove package
                                      </Button>
                                    </Grid>
                                  </React.Fragment>
                                );
                              })}
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                arrayHelpers.push({
                                  package_id: "",
                                  price: "",
                                });
                                isCustomPrices[packagesCount] = 0;
                                setIsCustomPrices(isCustomPrices);
                                setPackagesCount(packagesCount + 1);
                              }}
                            >
                              Add package
                            </Button>
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ClassLocationsFormDialog;
