import React, { useState, useContext, Fragment, useEffect } from "react";
import SchedulesFormDialog from "./SchedulesFormDialog";
import SchedulesListing from "./SchedulesListing";
import SchedulesSorting from "./SchedulesSorting";
import FAB from "../../components/FAB";
import { api } from "../../constants/Constants";
import Paginator from "../../components/Paginator";
import AppContext from "../../context/AppContext";
import EditIcon from "@material-ui/icons/Edit";

function Schedules(props) {
  const MODULE_URL = `/admin/${props.match.params.id}/schedules`;
  console.log(props);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(null);
  const [itemDialog, setItemDialog] = useState({
    item: null,
    open: false,
  });
  const [sortDialog, setSortDialog] = useState({
    tree: [],
    open: false,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    nextPage: null,
    prevPage: null,
    lastPage: null,
  });
  const context = useContext(AppContext);
  const { changeLoading, showSnack, checkAuthorization, changeRefreshing } =
    context;

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    getAll();
  }, [pagination.page]);

  const getAll = () => {
    changeLoading(true);
    const { page } = pagination;
    let url = `${MODULE_URL}?page=${page}&filters=${JSON.stringify(
      filters
    )}&related=${props.related}`;
    if (props.match.params.location) {
      url = `${MODULE_URL}?page=${page}&filters=${JSON.stringify(
        filters
      )}&related=${props.related}&location=${props.match.params.location}`;
    }
    api
      .get(url)
      .then((res) => {
        const response = res.data.data;
        setItems(response.data);
        // setPagination({
        //   ...pagination,
        //   nextPage: response.next_page_url,
        //   prevPage: response.prev_page_url,
        //   lastPage: response.last_page,
        // });
        changeLoading(false);
      })
      .catch(function (err) {
        console.log(err);
        changeLoading(false);
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleNext = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handlePrev = () => {
    setPagination({ ...pagination, page: pagination.page - 1 });
  };

  const handleLastPage = () => {
    setPagination({ ...pagination, page: pagination.lastPage });
  };

  const handleFirstPage = () => {
    setPagination({ ...pagination, page: 1 });
  };

  const handleClear = () => {
    setPagination({ page: 1, nextPage: null, prevPage: null, lastPage: null });
  };

  const handleFilter = (filters) => {
    setFilters(filters);
  };

  const fabCallback = () => {
    if (!checkAuthorization("create", "Schedules")) {
      showSnack("You don't have permission to create schedules", "error");
    } else {
      setItemDialog({ item: null, open: true });
    }
  };

  const handleCloseItemsFormDialog = () => {
    setItemDialog({ item: null, open: false });
  };

  const handleOpenEditFrom = () => {
    if (!checkAuthorization("update", "Schedules")) {
      showSnack("You don't have permission to edit schedules", "error");
    } else {
      setItemDialog({ open: true });
    }
  };

  const handleDelete = (toBeDeleted) => {
    changeRefreshing(true);
    const data = {};
    let url = `${MODULE_URL}/`;
    const method = "POST";

    if (toBeDeleted instanceof Array) {
      url += "destroyMany";
      data.ids = toBeDeleted;
    } else {
      url += toBeDeleted.toString();
    }
    url = `${url}?related=${props.related}`;
    data._method = "DELETE";
    api({ method, url, data })
      .then((res) => {
        changeRefreshing(false);
        getAll();
      })
      .catch((err) => {
        console.log(err);
        changeRefreshing(false);
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleExport = () => {
    changeLoading(true);
    api
      .get(`${MODULE_URL}/export?related=${props.related}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "schedules.xlsx"); //or any other extension
        document.body.appendChild(link);
        changeLoading(false);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        changeLoading(false);
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleStatusChange = (id, value) => {
    changeLoading(true);
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("is_active", value);
    api
      .post(
        `${MODULE_URL}/changeStatus/${id}?related=${props.related}`,
        formData
      )
      .then(() => {
        getAll();
      })
      .catch((err) => {
        console.log(err);
        changeLoading(false);
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleOpenSorting = () => {
    changeLoading(true);
    api
      .get(`${MODULE_URL}/tree?related=${props.related}`)
      .then((response) => {
        changeLoading(false);
        setSortDialog({ open: true, tree: response.data.data });
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const handleSortingSuccess = (tree) => {
    changeLoading(true);
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("tree", JSON.stringify(tree));
    api
      .post(`${MODULE_URL}/tree?related=${props.related}`, formData)
      .then((response) => {
        setSortDialog({ open: false, tree: [] });
        changeLoading(false);
        showSnack(response.data.message, "success");
      })
      .catch((err) => {
        console.log(err);
        changeLoading(false);
        setSortDialog({ open: false, tree: [] });
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  return (
    <Fragment>
      <SchedulesListing
        handleFilter={handleFilter}
        data={items}
        locationId={props.match.params.location}
        handleDelete={handleDelete}
        handleExport={handleExport}
        handleOpenEditFrom={handleOpenEditFrom}
        handleStatusChange={handleStatusChange}
        handleOpenSorting={handleOpenSorting}
      />
      <SchedulesFormDialog
        {...props}
        locationId={props.match.params.location}
        handleClose={handleCloseItemsFormDialog}
        handleSuccess={() => {
          setItemDialog({ open: false });
          getAll();
        }}
        isOpen={itemDialog.open}
        id={itemDialog.item}
      />
      <SchedulesSorting
        open={sortDialog.open}
        handleClose={() => {
          setSortDialog({ open: false, tree: [] });
        }}
        handleSuccess={handleSortingSuccess}
        tree={sortDialog.tree}
      />
      <FAB callback={fabCallback} Icon={EditIcon} />
    </Fragment>
  );
}

export default Schedules;
