import React, { Fragment, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import AppContext from "../../context/AppContext";
// Icons
import EventIcon from "@material-ui/icons/Event";
import LockIcon from "@material-ui/icons/Lock";
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import PhoneIcon from "@material-ui/icons/Phone";
import NavigationIcon from "@material-ui/icons/Navigation";
import GolfCourse from "@material-ui/icons/GolfCourse";
import Explore from "@material-ui/icons/Explore";
import Build from "@material-ui/icons/Build";
import Timer from "@material-ui/icons/Timer";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import HomeWork from "@material-ui/icons/HomeWork";
import Contacts from "@material-ui/icons/Contacts";
import ContactMail from "@material-ui/icons/ContactMail";
import Widgets from "@material-ui/icons/Widgets";
import PropertyTypes from "@material-ui/icons/Poll";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import AirplayIcon from "@material-ui/icons/Airplay";
import MenuIcon from "@material-ui/icons/Menu";
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from "@material-ui/icons/Home";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FormatColorTextIcon from "@material-ui/icons/FormatColorText";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import ContactsIcon from "@material-ui/icons/Contacts";
import ListIcon from "@material-ui/icons/List";
import TranslateIcon from "@material-ui/icons/Translate";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";

const activeClass = {
  backgroundColor: "#e4e8f0",
};
const groups = [
  {
    title: "Subscriptions",
    icon: <FormatColorTextIcon />,
    items: [
      {
        to: "/subscriptions",
        icon: <NavigationIcon />,
        title: "Subscriptions",
        permission: "view",
        module: "Subscriptions",
      },
      // {
      //   to: "/form_submissions/Coach",
      //   icon: <NavigationIcon />,
      //   title: "Coaches Submissions",
      //   permission: "view",
      //   module: "FormSubmissions",
      // },
      // {
      //   to: "/form_submissions/ClassProgram",
      //   icon: <NavigationIcon />,
      //   title: "Classes Submissions",
      //   permission: "view",
      //   module: "FormSubmissions",
      // },
      {
        to: "/forms",
        icon: <NavigationIcon />,
        title: "Move Passport",
        permission: "view",
        module: "MovePassport",
      },
    ],
  },
  {
    title: "Classes",
    icon: <Build />,
    items: [
      {
        to: "/classes",
        icon: <NavigationIcon />,
        title: "Classes",
        permission: "view",
        module: "Classes",
      },
      {
        to: "/private-training-requests",
        icon: <NavigationIcon />,
        title: "Private Requests",
        permission: "view",
        module: "PrivateTrainingRequests",
      },
      {
        to: "/packages",
        icon: <NavigationIcon />,
        title: "Packages",
        permission: "view",
        module: "Packages",
      },
      {
        to: "/locations",
        icon: <NavigationIcon />,
        title: "Locations",
        permission: "view",
        module: "Locations",
      },
      {
        to: "/promo-codes",
        icon: <NavigationIcon />,
        title: "Promo Codes",
        permission: "view",
        module: "PromoCodes",
      },
    ],
  },
  {
    title: "App Content",
    icon: <FormatColorTextIcon />,
    items: [
      // {
      //   to: "/phone_directory_categories",
      //   icon: <PhoneIcon />,
      //   title: "Phone Directory",
      //   permission: "view",
      //   module: "PhoneDirectoryCategories",
      // },
      // {
      //   to: "/events",
      //   icon: <EventIcon />,
      //   title: "Events",
      //   permission: "view",
      //   module: "Events",
      // },
      // {
      //   to: "/guidelines",
      //   icon: <LibraryBooksIcon />,
      //   title: "Guidelines",
      //   permission: "view",
      //   module: "Guidelines",
      // },
      {
        to: "/notifications",
        icon: <NotificationsIcon />,
        title: "Notifications",
        permission: "view",
        module: "Notifications",
      },
      {
        to: "/content",
        icon: <TranslateIcon />,
        title: "Localization",
        permission: "view",
        module: "AppSettings",
      },
      {
        to: "/screens",
        icon: <AirplayIcon />,
        title: "Screens",
        permission: "view",
        module: "Screens",
      },
      {
        to: "/menus",
        icon: <MenuIcon />,
        title: "Menus",
        permission: "view",
        module: "Menus",
      },
      {
        to: "/home-banners",
        icon: <NavigationIcon />,
        title: "Home Banners",
        permission: "view",
        module: "HomeBanners",
      },
      {
        to: "/home_widget",
        icon: <Widgets />,
        title: "Home Widget",
        permission: "view",
        module: "AppSettings",
      },
    ],
  },
  {
    title: "Contact Us",
    icon: <PermContactCalendarIcon />,
    items: [
      // {
      //   to: "/departments",
      //   icon: <ListIcon />,
      //   title: "Departments",
      //   permission: "view",
      //   module: "Departments",
      // },
      {
        to: "/contact_us",
        icon: <ContactsIcon />,
        title: "Submissions",
        permission: "view",
        module: "ContactUs",
      },
    ],
  },
  {
    title: "Admins",
    icon: <SupervisedUserCircle />,
    items: [
      {
        to: "/admins",
        icon: <SupervisedUserCircle />,
        title: "Admins",
        permission: "view",
        module: "Admins",
      },
      {
        to: "/roles/admins",
        icon: <LockIcon />,
        title: "Admins Roles",
        permission: "view",
        module: "Roles",
      },
    ],
  },
  /*{
    title: "Real Estate",
    icon: <ApartmentIcon/>,
    items: [
      {
        to: "/projects",
        icon: <ApartmentIcon/>,
        title: "Projects",
        permission: "view",
        module: "Projects",
      },
      {
        to: "/realEstatesubmissions",
        icon: <ContactMail/>,
        title: "Real Estate Inquiry",
        permission: "view",
        module: "RealEstateSubmissions",
      },

      {
        to: "/propertyTypes",
        icon: <PropertyTypes/>,
        title: "Property Types",
        permission: "view",
        module: "PropertyTypes",
      },
    ],
  },*/
  /*{
    title: "Activities",
    icon: <DirectionsRunIcon/>,
    items: [
      {
        to: "/activity_categories",
        icon: <GolfCourse/>,
        title: "Activity Categories",
        permission: "view",
        module: "ActivityCategories",
      },
      {
        to: "/activity_items",
        icon: <Explore/>,
        title: "Activity Items",
        permission: "view",
        module: "ActivityItems",
      },
    ],
  },*/
  {
    title: "Users",
    icon: <PeopleOutlineIcon />,
    items: [
      {
        to: "/users",
        icon: <PeopleOutlineIcon />,
        title: "Users",
        permission: "view",
        module: "Users",
      },
      {
        to: "/roles/users",
        icon: <LockIcon />,
        title: "Users Roles",
        permission: "view",
        module: "Roles",
      },
      {
        to: "/coaches",
        icon: <NavigationIcon />,
        title: "Coaches",
        permission: "view",
        module: "Coaches",
      },
      /*{
        to: "/relations",
        icon: <GroupIcon/>,
        title: "Relations",
        permission: "view",
        module: "Relations",
      },*/
      /*{
        to: "/imported_users",
        icon: <NavigationIcon />,
        title: "ImportedUsers",
        permission: "view",
        module: "ImportedUsers",
      },*/

      /*{
        to: "/rentals",
        icon: <HomeIcon/>,
        title: "Rentals",
        permission: "view",
        module: "Rentals",
      },*/
      // {
      //   to: "/settings",
      //   icon: <NavigationIcon />,
      //   title: "Settings",
      //   permission: "view",
      //   module: "Settings",
      // },
      /*{
        to: "/guest_invitations",
        icon: <NavigationIcon />,
        title: "Guest Invitations",
        permission: "view",
        module: "GuestInvitations",
      },*/
    ],
  },
  {
    title: "Settings",
    icon: <SettingsIcon />,
    items: [
      {
        to: "/appSettings",
        icon: <SettingsIcon />,
        title: "AppSettings",
        permission: "view",
        module: "AppSettings",
      },
    ],
  },

  /*{
    title: "Facilities",
    icon: <NavigationIcon/>,
    items: [
      {
        to: "/facilities",
        icon: <NavigationIcon/>,
        title: "Facilities",
        permission: "view",
        module: "Facilities",
      },
    ],
  },*/
];

export default function NavigationItems() {
  const [open, setOpen] = useState(null);
  const { checkAuthorization, logout } = useContext(AppContext);
  return (
    <Fragment>
      <NavLink
        to={"/"}
        exact
        style={{ textDecoration: "none" }}
        activeStyle={activeClass}
      >
        <Tooltip title="Dashboard" placement="right">
          <ListItem button style={{ height: 80, backgroundColor: "inherit" }}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography color="textPrimary">Dashboard</Typography>}
            />
          </ListItem>
        </Tooltip>
      </NavLink>
      {groups.map((group, groupIndex) => {
        return (
          <>
            <ListItem
              button
              onClick={() => {
                setOpen(open == groupIndex ? null : groupIndex);
              }}
              style={{ height: 80 }}
            >
              <ListItemIcon>{group.icon}</ListItemIcon>
              <ListItemText primary={group.title} />
              {open == groupIndex ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={open == groupIndex}
              timeout="auto"
              unmountOnExit
              style={{ marginLeft: 20 }}
            >
              <List component="div" disablePadding>
                {group.items.map((item) => (
                  <NavigationItem {...item} />
                ))}
              </List>
            </Collapse>
          </>
        );
      })}
      <Tooltip title="Logout" placement="right">
        <ListItem
          button
          style={{ height: "60px", backgroundColor: "inherit" }}
          onClick={logout}
        >
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText
            primary={<Typography color="textPrimary">Logout</Typography>}
          />
        </ListItem>
      </Tooltip>
    </Fragment>
  );
}

function NavigationItem(props) {
  const { checkAuthorization } = useContext(AppContext);
  const { to, title, icon, permission, module } = props;
  if (
    checkAuthorization &&
    permission &&
    module &&
    checkAuthorization(permission, module)
  ) {
    return (
      <NavLink
        to={to}
        style={{ textDecoration: "none" }}
        activeStyle={activeClass}
      >
        <Tooltip title={title} placement="right">
          <ListItem button style={{ height: 80, backgroundColor: "inherit" }}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
              primary={<Typography color="textPrimary">{title}</Typography>}
            />
          </ListItem>
        </Tooltip>
      </NavLink>
    );
  } else {
    return <></>;
  }
}
